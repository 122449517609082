import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ timerLength, timeoutOccured = () => {} }) => {
  const [timeLeft, setTimeLeft] = useState(timerLength * 60); // timerLength in seconds

  useEffect(() => {
    if (timeLeft <= 0) {
      timeoutOccured(); // Call the timeoutOccured function when timeLeft is 0
      return; // Stop the timer
    }

    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [timeLeft, timeoutOccured]);

  // Format time in m:ss
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className='text-red-500'>
      ({formatTime(timeLeft)})
    </div>
  );
};

export default CountdownTimer;
