import React from 'react';
import "./NextBtn.css";

const NextBtn = ({value, icon, bgColor, color, bold, centerSpacing, leftIcon, nextClick=()=>{}, padx=16, disabled}) => {
  return (
    <div className={`nextBtn bg-[${bgColor}] text-[${color}] ${bold ? 'font-bold' : ''} ${centerSpacing ? 'justify-between' : ''} ${disabled ? 'pointer-events-none' : ''} `} style={{padding: `12px ${padx}px`}} onClick={nextClick}>
        {leftIcon && <img className={`${centerSpacing ? 'pr-2': ''}`} src={icon} alt={value}/>}
        <div>{value}</div>
        {!leftIcon && <img className={`${centerSpacing ? 'pl-2': ''}`} src={icon} alt={value}/>}
    </div>
  )
}

export default NextBtn