import React from "react";

const MenuOptions = ({ id, value, icon, textSize, clickFun = () => {} }) => {
  const optionSize = {
    fontSize: `${textSize + 0.125}rem`,
  };
  return (
    <button className={`flex gap-3 py-5 px-8 font-normal`} style={optionSize} onClick={() => clickFun(id)}>
      <img src={icon} alt="icon" className="min-w-[1.5rem]" />
      {value}
    </button>
  );
};

export default MenuOptions;
