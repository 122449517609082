import React from 'react';
import "./NextBtn.css";

const NextBtn = ({value, icon, bgColor, color, bold, centerSpacing, nextClick=()=>{}, padx=16}) => {
  return (
    <div className={`nextBtn bg-[${bgColor}] text-[${color}] ${bold ? 'font-bold' : ''} ${centerSpacing ? 'justify-between' : ''}`} style={{padding: `12px ${padx}px`}} onClick={nextClick}>
        <div>{value}</div>
        <img className={`${centerSpacing ? 'pl-2': ''}`} src={icon} alt={value}/>
    </div>
  )
}

export default NextBtn