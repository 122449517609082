import React, { useRef, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import { transcribe } from "../../../Actions/ChatActions";
import AudioRecorder from "../AudioRecorder/AudioRecorder";
import { getUserToken } from "../../../Actions/Utils";
import RecordedAudioText from "../RecordedAudioText/RecordedAudioText";
import MicropohneOnboarding from "../MicrophoneOnboarding/MicrophoneOnboarding";
import { useNavigate } from "react-router-dom";
const microphone_enabled = process.env.REACT_APP_RECORDING === 'true'; 
const onboarding_delay = process.env.REACT_APP_ONBOARDING_DELAY;
const ChatInputButton = ({
  selectedLanguageCode,
  input,
  setInput = () => {},
  send = () => {},
  adjustTextareaHeight = () => {},
  isLoading,
  audioRef,
  stopPlayingAudio = () => {},
  setSpeakerEnabled = () => {},
  setToastErrorMessage = () => {},
  setShowToastMessage = () => {}
}) => {
  let navigate = useNavigate();
  const [isRecording, setIsRecording] = useState(false);
  const [isRecordOnboarding, setIsRecordOnboarding] = useState(false);
  const [Mp3Recorder, setMp3Recorder] = useState(
    new MicRecorder({ bitRate: 128 })
  );
  const [recordingTime, setRecordingTime] = useState(0);
  const recorderRef = useRef(null);
  const [audioText, setAudioText] = useState("");
  const [recordAgain, setRecordAgain] = useState(false);
  const [processAudio, setProcessAudio] = useState(false);

  const startRecording = async () => {
    try {
      await Mp3Recorder.start();
      setIsRecording(true);

      recorderRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    } catch (error) {
      console.error("Error starting audio recording:", error);
    }
  };

  const startRecordingOnboarding = () => {
    try {
      stopPlayingAudio(audioRef);
      setSpeakerEnabled(false);
      setIsRecordOnboarding(true);
      setTimeout(() => {
        setIsRecordOnboarding(false);
        startRecording();
      }, 3 * onboarding_delay);
    } catch(error) {
      console.error("Error startig audio recording onboarding:", error);
    }
  }

  const stopRecording = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(async ([buffer, blob]) => {
        setProcessAudio(true);
        const res = await transcribe(getUserToken(), selectedLanguageCode, blob);
        if (res?.status === 200) {
          setInput(res.data.transcript.text);
          setIsRecording(false);
          setAudioText(res.data.transcript.text);
          setProcessAudio(false);
          setRecordingTime(0);
        }
      })
      .catch((error) => {
        if (error["response"]["status"] === 401) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
        if (error["response"]["status"] === 500) {
          setToastErrorMessage(error["response"]["data"]["detail"])
          setShowToastMessage(true);
          setInput("");
          setIsRecording(false);
          setAudioText("");
          setProcessAudio(false);
          setRecordingTime(0);
        }
      });
    clearInterval(recorderRef.current);
  };

  function sendText() {
    if (!isLoading) {
      send(-1, input, true, null);
      setInput("");
      setAudioText("");
      adjustTextareaHeight(true);
    }
  }

  function sendMicText(inputText) {
    send(-1, inputText, true, null);
    setInput("");
    setAudioText("");
  }

  return (
    <div>
      {(input.length === 0 && microphone_enabled) && (
        <div
          className="flex items-center justify-center md:p-3 p-2  bg-[#434cb8] rounded-xl cursor-pointer hover:bg-[#3b44a2] shadow-md w-[2.75rem] h-[100%]"
          onClick={startRecordingOnboarding}
        >
          <img src={"/Assets/Images/mic.svg"} alt="Mic" className="" />
        </div>
      )}
      {(input.length > 0 || !microphone_enabled) && (
        <div
          className={`flex items-center justify-center md:p-3 p-2  bg-[#434cb8] rounded-xl cursor-pointer hover:bg-[#3b44a2] shadow-md w-[2.75rem] h-[100%] ${input.length > 0 ? '': 'pointer-events-none'}`}
          onClick={sendText}
        >
          <img src={"/Assets/Images/send.svg"} alt="Send" className="" />
        </div>
       )} 
      {
                isRecording && <div>
                    <div className='flex items-center justify-center md:p-3 p-2  bg-[#F55014] rounded-xl cursor-pointer' onClick={stopRecording}>
                        <img src="/Assets/Images/stopIcon.svg" alt='Stop' className='md:h-[100%] h-[70%]' />
                    </div>
                </div>
            }
      {isRecordOnboarding && (
          <MicropohneOnboarding selectedLanguageCode={selectedLanguageCode} onboarding_delay={onboarding_delay} />
        )
      }
      {isRecording && (
        <AudioRecorder selectedLanguageCode={selectedLanguageCode} recordingTime={recordingTime} onStop={stopRecording} showLoader={processAudio} />
      )}
      {audioText.length > 0 && (
        <RecordedAudioText
          audioText={audioText}
          selectedLanguageCode={selectedLanguageCode}
          send={sendMicText}
          setAudioText={setAudioText}
          setInput={setInput}
        />
      )}
    </div>
  );
};

export default ChatInputButton;
