import React, { useEffect, useState } from "react";
import "./Consent.css";
import ConsentContent from "../../../Shared/Components/ConsentContent/ConsentContent";
import { useNavigate } from "react-router-dom";
import { generateSpeech, saveConsent } from "../../../Actions/ChatActions";
import { getUserToken, saveConsentFlag, stopPlayingAudio } from "../../../Actions/Utils";
import NextBtn from "../../../Shared/Components/NextBtn/NextBtn";
import ToastMessage from "../../../Shared/Components/ToastMessage/ToastMessage";
import { ToastSeverityMap } from "../../../Constants/ToastConstants";


const Consent = ({ project, selectedLanguage, audioRef }) => {
  const [consentGiven, setCosentGiven] = useState(false);
  const [showSpeakerActive, setShowSpeakerActive] = useState(true);
  const [requestController, setRequestController] = useState(null);
  const [constants, setConstants] = useState(null);
  const navigate = useNavigate();
  const hideHeaderIconFlag = process.env.REACT_APP_HIDE_HEADER_ICON === 'true';
  const skipOnboardingFlag = process.env.REACT_APP_SKIP_ONBOARDING === 'true';
  const [accessToken, setAccessToken] = useState(null);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");
  const [audioResponse, setAudioResponse] = useState(null);

  useEffect(() => {
    const token = getUserToken();
    setAccessToken(token);
    if (!token) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };

    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/ConsentConstant`);
      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('popstate', handlePopState);
    }
  }, []);

  useEffect(() => {
    const handleAudio = async () => {
      if (constants && constants.consentAudio) {
        const res = await generateSpeech(consentAudio[selectedLanguage], selectedLanguage);
        if (res?.data?.url) {
          const newAudio = new Audio(res?.data?.url);
          if (audioRef.current) {
            audioRef.current.pause();
          }
          if (requestController) {
            requestController.abort();
          }

          audioRef.current = newAudio;
          setAudioResponse(true);
          newAudio.play().catch((err) => {
            console.error("Error playing audio:", err);
          });
        } else {
          setToastErrorMessage(res?.data?.detail)
          setShowToastMessage(true);
          setShowSpeakerActive(false);
        }
      }
    };

    if (showSpeakerActive) handleAudio();
  }, [showSpeakerActive, constants]);

  const handleConsentProceed = async () => {
    const res = await saveConsent(accessToken, true);
    if (res?.status === 200) {
      saveConsentFlag(true);
      stopPlayingAudio(audioRef);
      navigate("/chat");
    }
    if (res?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
    if (res?.status === 500) {
      setToastErrorMessage(res?.data?.detail)
      setShowToastMessage(true);
    }
  };
  const backArrowClick = () => {
    navigate("/guide");
  };


  if (!constants) {
    return <div>Loading...</div>;
  }

  const { consent, consent_content, consent_headers, consnent_data, consentAudio, consentButton, consent_title } = constants;

  return (
    <>
      <div className="consent-page h-screen w-screen">
        <div className="w-full flex items-center justify-between bg-[var(--neutral-white)] p-4 text-[color:var(--black)] text-xl font-semibold">
          <div className="loginNextBtn">
            {!skipOnboardingFlag && <NextBtn padx={0} icon={"/Assets/Images/arrowLeftBlack.svg"} nextClick={backArrowClick} />}
          </div>
          <div className=" flex items-center gap-3">
            {/* {!hideHeaderIconFlag && <img className="max-h-10 rounded-full w-10 h-10" src={"/Assets/Images/asha_icon_square.svg"} alt="asha" />} */}
            {consent_title[selectedLanguage].heading} {consent_title[selectedLanguage].sub_heading}
          </div>
          {showSpeakerActive ? (
            <div
              className="bg-[#D5D7EE] rounded-full h-12 w-12 flex items-center justify-center p-1"
              onClick={() => {
                setShowSpeakerActive(!showSpeakerActive);
                stopPlayingAudio(audioRef);
              }}
            >
              <div className="bg-[var(--primary-dark)] rounded-full flex items-center justify-center h-[100%] w-[100%]">
                <img
                  src={"/Assets/Images/whiteSpeaker.svg"}
                  alt="Active Speaker"
                />
              </div>
            </div>
          ) : (
            <div
              onClick={() => {
                setShowSpeakerActive(!showSpeakerActive);
              }}
            >
              <img
                src={"/Assets/Images/crossSpeakerIcon.svg"}
                alt="Inactive Speaker"
              />
            </div>
          )}
        </div>
        <div className="flex flex-col px-4 py-6 gap-5">
          <div className="flex flex-col items-center py-5 px-1.5 rounded-2xl bg-[var(--neutral-white)] border border-[#EBEBEB] shadow-lg">
            {/* <div className="image-div rounded-lg"></div> */}
            <div className='text-[var(--almost-black)] font-["Noto_Sans"] font-bold text-lg tracking-wide leading-relaxed mt-4'>
              {consent_headers.header[selectedLanguage]}
            </div>
            <div className='text-[var(--dark-neutral-2)] font-["Noto_Sans"] text-base font-normal leading-double tracking-wide'>
              {/* {consent_headers.subheader[selectedLanguage]} */}
            </div>
            {consent_content.map((itm, idx) => {
              return (
                <div key={idx} className="flex flex-col py-4 px-5 gap-5">
                  <ConsentContent
                    icon={itm.image}
                    header={itm.header[selectedLanguage]}
                    text={itm.text[selectedLanguage]}
                  />
                  {idx < consent_content.length - 1 ? (
                    <div className="divider" />
                  ) : null}
                </div>
              );
            })}
            <div className="rounded-lg bg-[var(--light-neutral-2)] py-5 px-7 flex flex-col gap-2.5">
              <div className="text-[color:var(--black)] text-sm font-medium font-['Noto_Sans']">
                {consnent_data.question[selectedLanguage]}
              </div>
              <div className="text-[color:var(--dark-neutral-2)] text-sm font-normal font-['Noto_Sans']">
                {consnent_data.text[selectedLanguage]}
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <div
              className={`consent-input-wrapper ${consentGiven ? "consent-given" : ""
                }`}
            >
              <input
                type="checkbox"
                className="custom-checkbox"
                value={consentGiven}
                onChange={() => {
                  setCosentGiven(!consentGiven);
                }}
              />
              <div className="text-sm font-normal text-[color:var(--black)]">
                {consent[selectedLanguage]}
              </div>
            </div>
            {audioResponse && consentGiven && (
              <div
                className='w-full rounded-lg border border-[var(--light-neutral-4)] bg-[var(--primary-dark)] flex gap-2 py-3 px-4 justify-center items-center text-[color:var(--neutral-white)] text-base font-normal font-["Roboto"]'
                onClick={() => handleConsentProceed()}
              >
                {consentButton[selectedLanguage]}
                <img src={"/Assets/Images/nextWhiteIcon.svg"} alt="proceed" />
              </div>
            )}
          </div>
        </div>
      </div>
      {
        showToastMessage && <ToastMessage message={toastErrorMessage} severity={ToastSeverityMap.error} direction={"bottom"} close={setShowToastMessage}/>
      }
    </>
  );
};

export default Consent;
