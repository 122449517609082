import "./App.css";
import "./color-constants.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ChatBody from "./Core/Components/ChatBody/ChatBody";
import { useEffect, useRef, useState } from "react";
import Introduction from "./Core/Components/Introduction/Introduction";
import LoginComponent from "./Core/Components/Auth/LoginComponent/LoginComponent";
import Guide from "./Core/Components/Guide/Guide";
import Consent from "./Core/Components/Consent/Consent";
import Device_readiness_Consent from "./Core/Components/Device_readiness_Consent/Device_readiness_Consent";
import Device_readiness_Intro from "./Core/Components/Device_readiness_Intro/Device_readiness_Intro";
import Device_readiness_Form from "./Core/Components/Device_readiness_Form/Device_readiness_Form";
import Device_readiness_End from "./Core/Components/Device_readiness_End/Device_readiness_End";
import Device_readiness_Record from "./Core/Components/Device_readiness_Record/Device_readiness_Record";
import NetworkNotification from "./Shared/Components/NetworkNotification/NetworkNotification";
import serviceWorkerRegistration from "./serviceWorkerRegistration";
import RegisterComponent from "./Core/Components/Auth/RegisterComponent/RegisterComponent";
import RegisterSuccessComponent from "./Core/Components/Auth/RegisterSuccessComponent/RegisterSuccessComponent";
import { getDefaultLanguage, setDefaultLanguage } from "./Actions/Utils";
import { checkMiddleWareHealth } from "./Actions/ChatActions";
import StaticHTML from "./Core/Components/StaticHTML/StaticHTML";

function App() {
  const [selectedLanguage, setSelectedLanguage] = useState(
    getDefaultLanguage() || "hi"
  );
  const [textSize, setTextSize] = useState(1); // Initial text size in rem
  const audioRef = useRef();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showOnlinePopup, setShowOnlinePopup] = useState(false);
  const [isTryingToConnect, setIsTryingToConnect] = useState(false);
  const [showStatusBar, setShowStatusBar] = useState(false);
  const project = process.env.REACT_APP_PROJECT;
  const [showMiddlewarePopup, setShowMiddlewarePopup] = useState(false);
  const [middlewareLiveStatus, setMiddlewareLiveStatus] = useState(null);
  const [retryMiddlewareStatus, setRetryMiddlewareStatus] = useState(null);
  const [networkConstants, setNetworkConstants] = useState(null);
  const [middleliveConstants, setMiddleliveConstants] = useState(null);

  const getCurrentHour = () => {
    const now = new Date();
    return now.getHours();
  };

  const isRestrictedTime = () => {
    const hour = getCurrentHour();
    return hour >= 0 && hour < 8; // Midnight to 8 AM
  };

  const decreaseTextSize = (event) => {
    event.preventDefault();
    setTextSize((prevSize) => Math.max(prevSize - 0.125, 0.5)); // Minimum size
  };

  const increaseTextSize = (event) => {
    event.preventDefault();
    setTextSize((prevSize) => Math.min(prevSize + 0.125, 3)); // Maximum size
  };

  const middlewareHealth = async () => {
    const res =  await checkMiddleWareHealth();
    setShowMiddlewarePopup(!res);
    setMiddlewareLiveStatus(String(res));
    return res;
  } 

  const tryToReconnect = () => {
    setIsTryingToConnect(true);
    setTimeout(() => {
      if (navigator.onLine) {
        setIsOnline(true);
        setShowOnlinePopup(true);
      } else {
        setIsTryingToConnect(false);
      }
    }, 2000);
  };

  const tryToReconnectMiddleware = async () => {
      setRetryMiddlewareStatus(true);
      const res = await checkMiddleWareHealth();
      setTimeout(() => {
        if (res) {
          setMiddlewareLiveStatus(String(res));
          setShowMiddlewarePopup(res);
          setRetryMiddlewareStatus(res);
        } else {
          setRetryMiddlewareStatus(false);
        }
    }, 2000);
  };

  useEffect(() => {
    const onUpdate = (registration) => {
      if (window.confirm("New version available! Would you like to update?")) {
        const worker = registration.waiting;
        if (worker) {
          worker.postMessage({ action: "skipWaiting" });
          worker.addEventListener("statechange", (event) => {
            if (event.target.state === "activated") {
              window.location.reload(true);
            }
          });
        }
      }
    };

    serviceWorkerRegistration({ onUpdate });
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if(middlewareLiveStatus === null) {
        middlewareHealth();
      }
    }, 60000);

    middlewareHealth();
    
    const handleOnline = () => {
      setIsOnline(true);
      setShowOnlinePopup(true);
      setIsTryingToConnect(false);
    };

    const handleOffline = () => {
      setIsOnline(false);
      setShowOnlinePopup(true);
    };

    const loadConstants = async () => {
      const networkModule = await import(`./Constants/${project}/NetworkConstants`);
      setNetworkConstants(networkModule);
      const middlewareLiveModule = await import(`./Constants/${project}/MiddlewareConstants`);
      setMiddleliveConstants(middlewareLiveModule);
    }

    loadConstants();

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    if (isOnline) {
      const timer = setTimeout(() => {
        setShowOnlinePopup(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isOnline]);

  useEffect(() => {
    setShowStatusBar(showOnlinePopup || isTryingToConnect);
  }, [showOnlinePopup, isTryingToConnect]);

  useEffect(() => {
    setShowStatusBar(middlewareLiveStatus);
  }, [middlewareLiveStatus]);

  useEffect(() => {
    setDefaultLanguage(selectedLanguage);
  },[selectedLanguage]);

  if (isRestrictedTime()) {
    return <StaticHTML />;
  }

  if (!networkConstants || !middleliveConstants) {
    return <div>Loading...</div>;
  }


  const { onlineText, offlineText, retryText, tryAgainText } = networkConstants;
  const { liveText, downText, retryLiveText, tryAgainLiveText } = middleliveConstants;

  return (
    <div className="h-screen w-screen overflow-hidden">
      <Router>
        <Routes>
          <Route
            path="/chat"
            element={
              <ChatBody
                project={project}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                textSize={textSize}
                decreaseTextSize={decreaseTextSize}
                increaseTextSize={increaseTextSize}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/"
            element={
              <Introduction
                project={project}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/otp-verify"
            element={
              <LoginComponent
                project={project}
                selectedLanguage={selectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/register"
            element={
              <RegisterComponent
                project={project}
                selectedLanguage={selectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/register-success"
            element={
              <RegisterSuccessComponent
                project={project}
                selectedLanguage={selectedLanguage}
              />
            }
          />
          <Route
            path="/guide"
            element={
              <Guide
                project={project}
                selectedLanguage={selectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/consent"
            element={
              <Consent
                project={project}
                selectedLanguage={selectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/device_readiness/consent"
            element={
              <Device_readiness_Consent
                project={project}
                selectedLanguage={selectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/device_readiness/intro"
            element={
              <Device_readiness_Intro
                project={project}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/device_readiness/form"
            element={
              <Device_readiness_Form
                project={project}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/device_readiness/thanku"
            element={
              <Device_readiness_End
                project={project}
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
                audioRef={audioRef}
              />
            }
          />
          <Route
            path="/device_readiness/record"
            element={
              <Device_readiness_Record
                project={project}
                selectedLanguage={selectedLanguage}
              />
            }
          />
        </Routes>
      </Router>
      {(showOnlinePopup || isTryingToConnect) && (
        <NetworkNotification
          isOnline={isOnline}
          isTryingToConnect={isTryingToConnect}
          tryToReconnect={tryToReconnect}
          successText={onlineText[selectedLanguage]}
          failureText={offlineText[selectedLanguage]} 
          tryingText={retryText[selectedLanguage]}
          tryAgainText={tryAgainText[selectedLanguage]}
        />
      )}
      {showMiddlewarePopup && (
        <NetworkNotification
          isOnline={middlewareLiveStatus === "true" ? true : false}
          isTryingToConnect={retryMiddlewareStatus}
          tryToReconnect={tryToReconnectMiddleware}
          successText={liveText[selectedLanguage]}
          failureText={downText[selectedLanguage]} 
          tryingText={retryLiveText[selectedLanguage]}
          tryAgainText={tryAgainLiveText[selectedLanguage]}
        />
      )}
    </div>
  );
}

export default App;
