export const title = {
    en: "Help & Feedback",
    hi: "हेल्प  और फीडबैक"
}

export const subTitle = {
    en : "Please share your concerns or feedback with us.",
    hi: "कृपया अपनी चिंताएँ या फीडबैक हमारे साथ साझा करें।"
}

export const textPlaceholder = {
    en: "Type your feedback here",
    hi: "अपना फ़ीडबैक टाइप करें"
}

export const submitLabel = {
    en: "Submit",
    hi: "जमा करें"
}