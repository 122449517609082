export const errorCodeMap = {
    "No Answer": 301,
    "Third Party API Error": 302,
    "Internal Server Error": 303,
    "General ML Codebase Error": 304,
    "Translation API Error": 305,
    "Invalid OpenAI model output": 500,
    "Model is not responding": 501
}

export const modelNotRespondingConstant = {
    "en": "I am experiencing a problem in generating response. Please wait a while and try again.",
    "hi": "मुझे जवाब तैयार करने में समस्या आ रही है। कृपया थोड़ी देर इंतजार करें और पुनः प्रयास करें।" 
}