import React, { useState } from "react";
import ChatQueueBlock from "../ChatQueueBlock/ChatQueueBlock";
import "./ChatQueue.css";
import { stopPlayingAudio } from "../../../Actions/Utils";

const ChatQueue = ({
  project,
  selectedLanguage,
  currentBlock,
  chatArr,
  micStatus,
  isLoading,
  stopAudioFlag,
  openSourceDialog = () => {},
  populateChat = () => {},
  textSize,
  sendFeedback = () => {},
  submitResponse = () => {},
  audioRef,
  speakerEnabled,
  setSpeakerEnabled = () => {},
  markFavourite = () => {},
  setToastErrorMessage = () => {},
  setShowToastMessage = () => {}
}) => {
  const currQuesRef = React.useRef(null);
  const [requestController, setRequestController] = useState(null);
  // Initialize a variable to keep track of the previous date
  let prevDate = null;
  // Initialize a variable to keep track if the first date has been encountered
  let isFirstDateEncountered = false;

  const playAudio = (url) => {
    const newAudio = new Audio(url);
    if (audioRef.current) {
      audioRef.current.pause();
    }
    if (requestController) {
      requestController.abort();
    }

    newAudio.addEventListener("ended", () => {
      setSpeakerEnabled(false);
    });

    audioRef.current = newAudio;
    newAudio.play().catch((err) => {
      console.error("Error playing audio:", err);
    });
  };

  const stopAudio = () => {
    if (audioRef.current) {
      setSpeakerEnabled(false);
      stopPlayingAudio(audioRef);
    }
  };

  const scrollToBottom = () => {
    const chatAreaDiv = document.getElementById("chatQueue");
    if (currQuesRef?.current?.offsetTop !== chatAreaDiv.offsetTop) {
      currQuesRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  React.useEffect(() => {
    stopAudio();
  }, [stopAudioFlag]);

  React.useEffect(scrollToBottom, [currentBlock]);

  return (
    <div id="chatQueue" className="flex flex-col justify-between mt-auto">
      {chatArr.length === 0
        ? ""
        : chatArr.map((itm, idx) => {
            // Check if the current date is different from the previous one
            const currentDate = new Date(itm?.bot?.datetime).toDateString(); // Assuming datetime is a JavaScript Date object
            const showDate =
              !prevDate ||
              (prevDate !== currentDate && currentDate !== "Invalid Date");
            prevDate = currentDate;

            // Set isFirstDateEncountered to true once the first date is encountered
            if (!isFirstDateEncountered && showDate) {
              isFirstDateEncountered = true;
            }
            return (
              <div
                key={idx}
                ref={idx === chatArr.length - 1 ? currQuesRef : null}
              >
                <ChatQueueBlock
                  project={project}
                  idx={idx}
                  followingIdx={idx + 0.1}
                  item={itm}
                  isLoading={isLoading}
                  selectedLanguage={selectedLanguage}
                  date={new Date(itm?.bot?.datetime)}
                  micStatus={micStatus}
                  requestController={requestController}
                  setRequestController={setRequestController}
                  openSourceDialog={openSourceDialog}
                  speakerEnabled={speakerEnabled}
                  setSpeakerEnabled={setSpeakerEnabled}
                  playAudio={playAudio}
                  stopAudio={stopAudio}
                  showDate={showDate}
                  textSize={textSize}
                  sendFeedback={sendFeedback}
                  submitResponse={submitResponse}
                  disabled={idx < chatArr.length - 1}
                  markFavourite={markFavourite}
                  setToastErrorMessage={setToastErrorMessage}
                  setShowToastMessage={setShowToastMessage}
                />
              </div>
            );
          })}
    </div>
  );
};

export default ChatQueue;
