import { useState } from "react";

const StarChatBlock = ({id, idx, starred, mark = () => { }}) => {
    return (
        <div className="cursor-pointer">
            {starred && <img src={'./Assets/Images/FilledStar.svg'} onClick={() => mark(idx, id, false)} alt="star" />}
            {!starred && <img src={'./Assets/Images/BlankStar.svg'} onClick={() => mark(idx, id, true)} alt="star" />}
        </div>
    )
}

export default StarChatBlock;