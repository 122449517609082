const OptionChips = ({
  option,
  selectedLanguage,
  send = () => {},
  textSize,
  disabled,
  index,
  selectedChipIndex
}) => {
  function saveResponse(input, image) {
    send(index, input, false, image);
  }
  return (
    <div
      className={`flex items-center justify-center rounded-lg border border-solid border-1 border-[var(--primary-dark)] gap-3  min-h-[3rem]  ${disabled ? 'bg-[var(--light-neutral-3)] opacity-60 border-[var(--light-neutral-3)]': 'bg-[var(--neutral-white)]'} ${selectedChipIndex == index ? 'opacity-100 bg-[var(--primary-dark)] text-[var(--neutral-white)]': 'text-[var(--primary-dark)]'}`}
      onClick={() => saveResponse(option.value[selectedLanguage], option.img)}
      style={{ fontSize: `${textSize}rem` }}
    >
      {option.img && <img
          src={option.img}
          alt={option.value[selectedLanguage]}
          className="h-[100%]"
        />}
      <div className="m-2 text-center">
        {option.value[selectedLanguage]}
      </div>
    </div>
  );
};

export default OptionChips;
