import React, { useEffect, useState } from "react";
import "./Guide.css";
import { useNavigate } from "react-router-dom";
import { generateSpeech, saveOnboarding } from "../../../Actions/ChatActions";
import { getConsentFlag, getUserToken, saveOnboardingFlag, stopPlayingAudio } from "../../../Actions/Utils";
import ToastMessage from "../../../Shared/Components/ToastMessage/ToastMessage";
import { ToastSeverityMap } from "../../../Constants/ToastConstants";

const Guide = ({ project, selectedLanguage, audioRef }) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [disableButtons, setDisableButtons] = useState(true);
  const [showSpeakerIcon, setShowSpeakerIcon] = useState(false);
  const [showSpeakerActive, setShowSpeakerActive] = useState(true);
  const [requestController, setRequestController] = useState(null);
  const [constants, setConstants] = useState(null);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");

  useEffect(() => {
    if (!getUserToken()) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };
    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/GuideConstant`);
      setConstants(module);
    }

    loadConstants();

    document.addEventListener('visibilitychange', handleVisibilityChange);

    window.history.pushState(null, document.title, window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('popstate', handlePopState);
    }
  }, []);

  useEffect(() => {
    const handleAudio = async () => {
      if (constants && constants.guide) {
        const res = await generateSpeech(constants.guide[index].audio[selectedLanguage], selectedLanguage);
        if (res?.data?.url) {
          const newAudio = new Audio(res?.data?.url);
          if (audioRef.current) {
            audioRef.current.pause();
          }
          if (requestController) {
            requestController.abort();
          }
          
          newAudio.addEventListener("ended", () => {
            setDisableButtons(false);
          });
    
          audioRef.current = newAudio;
          newAudio.play().catch((err) => {
            console.error("Error playing audio:", err);
          });
        } else {
          setToastErrorMessage(res?.data?.detail)
          setShowToastMessage(true);
          setShowSpeakerActive(false);
          setDisableButtons(false);
        }
      }
    };
  
    if (showSpeakerActive && constants && constants.guide) {
      handleAudio();
    }
  
    setTimeout(() => {
      setDisableButtons(false);
    }, 9000);
  }, [index, showSpeakerActive, constants]);
  

  const handlePrev = () => {
    setIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    stopPlayingAudio(audioRef);
    setDisableButtons(true);
  };

  const handleNext = () => {
    if (index < guide?.length - 1) {
      setIndex((prevIndex) => Math.min(prevIndex + 1, guide?.length - 1));
      stopPlayingAudio(audioRef);
      setDisableButtons(true);
    } else {
      handleSkip();
    }
  };

  const handleSkip = async () => {
    const res = await saveOnboarding(getUserToken(), true);
    if (res?.status === 200) {
      saveOnboardingFlag(true);
      if (getConsentFlag()) {
        stopPlayingAudio(audioRef);
        navigate("/chat");
      } else {
        stopPlayingAudio(audioRef);
        navigate("/consent");
      }
    }
    if (res?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
    if(res?.status === 500) {
      setToastErrorMessage(res?.data?.detail)
      setShowToastMessage(true);
    }
  };

  useEffect(() => {
    setShowSpeakerIcon(false);
    const timer = setTimeout(() => {
      setShowSpeakerIcon(true);
      setTimeout(() => {
        setShowSpeakerIcon(false);
      }, 2000);
    }, 2000);

    return () => clearTimeout(timer);
  }, [index]);

  useEffect(() => {
    const handleBackButton = () => {
      // Perform your desired action when the back button is pressed
    };

    const unlisten = () => {
      window.removeEventListener('popstate', handleBackButton);
    };

    window.addEventListener('popstate', handleBackButton);

    return unlisten;
  }, []);

  if (!constants) {
    return <div>Loading...</div>;
  }

  const { guide, guideButtons } = constants;

  return (
    <>
      <div className="relative guideComponent h-screen w-screen mix-blend-luminosity flex flex-col items-center">
        <div className="w-full p-6 flex justify-end fixed top-0">
          <div
            className={`speakerDiv ${showSpeakerIcon && showSpeakerActive ? "Active" : ""
              }`}
            onClick={() => {
              setShowSpeakerActive(!showSpeakerActive);
              stopPlayingAudio(audioRef);
              setDisableButtons(false);
            }}
          >
            {showSpeakerIcon && showSpeakerActive ? guideButtons.stop[selectedLanguage] : null}
            {showSpeakerActive ? (
              <div className="">
                <img
                  src={"/Assets/Images/blueSpeaker.svg"}
                  alt="active Speaker"
                />
              </div>
            ) : (
              <div className="">
                <img src={"/Assets/Images/crossSpeakerIcon.svg"} alt="speaker" />
              </div>
            )}
          </div>
        </div>
        <div className="h-full bg-[rgba(50,55,160,0.25)]">
          <div className="h-[70%] flex flex-col items-center justify-center gap-6">
            <img src={`./Assets/Images/` + guide[index].image} className="rounded-3xl w-fit h-[35%]" alt="guide" />


            <div className="text-[var(--black)] text-center font-medium text-xl w-[80%]">
              {guide[index].text[selectedLanguage]}
            </div>
          </div>
          <div className="absolute rounded-t-2xl bottom-0 w-full flex backdrop-blur-md bg-[rgba(61,64,99,0.42)] h-[30%]">
            <div
              className="w-1/2 h-full flex flex-col items-center justify-center"
            >
              {index > 0 && <div className="flex flex-col items-center justify-center text-[color:var(--neutral-white)] font-['Roboto']" onClick={() => {
                handlePrev();
              }}>
                <img src={"/Assets/Images/previousWhiteIcon.svg"} alt="previous" />
                {guideButtons.previous[selectedLanguage]}
              </div>}
            </div>
            <div className="h-full border-r border-dashed border-[var(--neutral-white)] opacity-30" />
            {!disableButtons && (
              <div
                className="w-1/2 h-full flex flex-col items-center justify-center text-[color:var(--neutral-white)] font-['Roboto']"
                onClick={() => {
                  if (index < guide.length - 1) {
                    handleNext();
                  } else {
                    handleSkip();
                  }
                }}
              >
                <img src={"/Assets/Images/nextWhiteIcon.svg"} alt="next" />
                {guideButtons.next[selectedLanguage]}
              </div>
            )}
            {!disableButtons && (index < guide.length - 1) && (
              <div
                className="fixed bottom-7 left-1/2 transform -translate-x-1/2 bg-[#373B6857] text-[color:var(--neutral-white)] flex rounded-3xl px-4 py-2 md:px-6 md:py-4"
                onClick={handleSkip}
              >
                {guideButtons.skip[selectedLanguage]}
                <img src={"/Assets/Images/skipIcon.svg"} alt="skip" />
              </div>
            )}
          </div>
        </div>
      </div>
      {
        showToastMessage && <ToastMessage message={toastErrorMessage} severity={ToastSeverityMap.error} direction={"bottom"} close={setShowToastMessage}/>
      }
    </>
  );
};

export default Guide;