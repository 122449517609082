// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro-heading {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "DM Sans";
    color: var(--neutral-white);
}

.intro-subheading {
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: "Satoshi";
    color: var(--neutral-white);
}

.intro-lang {
    font-family: "Noto Sans";
}

.into-lang-buttons {
    display: flex;
    border-radius: 29px;
    border: 1px solid var(--neutral-white);
    align-items: center;
    padding: 1px;
    width: 50vw;
}

.introselectedLngBtn {
    background: var(--neutral-white);
    color: var(--primary-dark);
    border-radius: 23px;
}

.intronormalBtn {
    color: var(--neutral-white);
    opacity: 80%;
}`, "",{"version":3,"sources":["webpack://./src/Core/Components/Introduction/Introduction.css"],"names":[],"mappings":"AAAA;IACI,6CAA6C;IAC7C,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,6CAA6C;IAC7C,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sCAAsC;IACtC,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,gCAAgC;IAChC,0BAA0B;IAC1B,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,YAAY;AAChB","sourcesContent":[".intro-heading {\n    font-feature-settings: 'clig' off, 'liga' off;\n    font-family: \"DM Sans\";\n    color: var(--neutral-white);\n}\n\n.intro-subheading {\n    font-feature-settings: 'clig' off, 'liga' off;\n    font-family: \"Satoshi\";\n    color: var(--neutral-white);\n}\n\n.intro-lang {\n    font-family: \"Noto Sans\";\n}\n\n.into-lang-buttons {\n    display: flex;\n    border-radius: 29px;\n    border: 1px solid var(--neutral-white);\n    align-items: center;\n    padding: 1px;\n    width: 50vw;\n}\n\n.introselectedLngBtn {\n    background: var(--neutral-white);\n    color: var(--primary-dark);\n    border-radius: 23px;\n}\n\n.intronormalBtn {\n    color: var(--neutral-white);\n    opacity: 80%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
