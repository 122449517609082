import React from "react";
import NavBar from "../../../Shared/Components/NavBar/NavBar";
import SideMenu from "../SideMenu/SideMenu";

const TopBar = ({
  project,
  userData,
  isMenuOpen,
  toggleMenu = () => {},
  selectedLanguage,
  setSelectedLanguage = () => {},
  textSize,
  decreaseTextSize = () => {},
  increaseTextSize = () => {},
  toggleHelp = () => {},
}) => {
  return (
    <div className="">
      <NavBar project={project} selectedLanguage={selectedLanguage} textSize={textSize} toggleMenu={toggleMenu} toggleHelp={toggleHelp} />
      {isMenuOpen && (
        <SideMenu
          project={project}
          userData={userData}
          toggleMenu={toggleMenu}
          selectedLanguage={selectedLanguage}
          setSelectedLanguage={setSelectedLanguage}
          textSize={textSize}
          decreaseTextSize={decreaseTextSize}
          increaseTextSize={increaseTextSize}
          toggleHelp={toggleHelp}
        />
      )}
    </div>
  );
};

export default TopBar;
