const SpeakerChatBlock = ({idx, title, options, isPlaying, startAudio = () => { }, stopAudio = () => { }}) => {
    return (
        <div>
            {!isPlaying && <div className='rounded-full bg-[var(--neutral-white)] flex items-center justify-center p-2' onClick={() => startAudio(idx, title, options)}>
                <img src={'./Assets/Images/graySpeaker.svg'} alt="speaker" />
            </div>}
            {isPlaying && <div className='rounded-full bg-[var(--primary-dark)] flex items-center justify-center p-2' onClick={() => stopAudio()}>
            <img src={'./Assets/Images/whiteSpeaker.svg'} alt="speaker" />
            </div>}
        </div>
    )
}

export default SpeakerChatBlock;