export const sideMenu = [
    {
        "id": "view tutorial",
        "value": {
            "English": "View Tutorial",
            "हिंदी": "ट्यूटोरियल देखें",
        },
        "icon": "/Assets/Images/youtubeIcon.svg",
    },
    // {
    //     "id": "give feedback",
    //     "value": {
    //         "English": "Give feedback",
    //         "हिंदी": "Give Feedback",
    //     },
    //     "icon": "/Assets/Images/messageSquare.svg",
    // },
    {
        "id": "help and feedback",
        "value": {
            "English": "Help & Feedback",
            "हिंदी": "हेल्प और फीडबैक",
        },
        "icon": "/Assets/Images/supportCircle.svg",
    },
]