import { useEffect, useState } from "react";
import "./LoginComponent.css";
import NextBtn from "../../../../Shared/Components/NextBtn/NextBtn";
import { useNavigate } from "react-router-dom";
import { verifyOtp, resendOtp } from "../../../../Actions/UserActions";
import { getMobileNumber, saveConsentFlag, saveOnboardingFlag, setUserProfileData, setUserToken, setUserType, stopPlayingAudio } from "../../../../Actions/Utils";
import { generateSpeech } from "../../../../Actions/ChatActions";
import CountdownTimer from "../../../../Shared/Components/CountdownTimer/CountdownTimer";
import ToastMessage from "../../../../Shared/Components/ToastMessage/ToastMessage";
import { ToastSeverityMap } from "../../../../Constants/ToastConstants";

const LoginComponent = ({ project, selectedLanguage, audioRef }) => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [showErrorFlag, setShowErrorFlag] = useState(false);
  const [disableButtons, setDisableButtons] = useState(true);
  const [showSpeakerIcon, setShowSpeakerIcon] = useState(false);
  const [showSpeakerActive, setShowSpeakerActive] = useState(false);
  const [requestController, setRequestController] = useState(null);
  const [loginConstants, setLoginConstants] = useState(null);
  const [guideConstants, setGuideConstants] = useState(null);
  const skipOnboardingFlag = process.env.REACT_APP_SKIP_ONBOARDING === 'true';
  const mobileNumber = getMobileNumber();
  const timerLength = 1;
  const [resendOtpFlag, setResendOtpFlag] = useState(false);
  const [showTimerFlag, setShowTimerFlag] = useState(true);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");

  useEffect(() => {
    const loadConstants = async () => {
      const loginModule = await import(`../../../../Constants/${project}/LoginConstant`);
      const guideModule = await import(`../../../../Constants/${project}/GuideConstant`);
      setLoginConstants(loginModule);
      setGuideConstants(guideModule);
    };

    loadConstants();

    stopPlayingAudio(audioRef);
    window.history.pushState(null, document.title, window.location.href);

    const handlePopState = (event) => {
      window.history.pushState(null, document.title, window.location.href);
    };

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const goToNext = (data) => {
    console.log(data?.onboarding, data?.consent);
    
    if (!data?.onboarding) {

      navigate("/guide");
    }
    else {
      saveOnboardingFlag(data?.onboarding);
      if (!data?.consent){
        navigate("/consent");
      }
      else {
        saveConsentFlag(data?.consent);
        navigate("/chat");
      }
    }
  };

  const backArrowClick = () => {
    navigate("/");
  };

  const optTimeoutOccured = () => {
    setShowTimerFlag(false);
    setResendOtpFlag(true);
  }

  const handleOtpChange = (e) => {
    setShowErrorFlag(null);
    const value = e.target.value;
    // Check if the entered value is numeric and not exceeding 4 digits
    if (/^\d{0,5}$/.test(value)) {
      setOtp(value);
    }
  };

  const verifyOtpFun = async () => {
    const res = await verifyOtp("+91" + mobileNumber, otp);
    if (res?.status === 200) {
      if (res?.data?.token) {
        setUserToken(res?.data?.token);
        res["data"]["user_data"]["mobile"] = mobileNumber;
        setUserType(res?.data?.user_data?.role);
        setUserProfileData(res?.data?.user_data?.profile);
        goToNext(res?.data?.user_data);
      }
    }
    if(res?.status === 401) {
      setShowErrorFlag(true);
    }
    if (res?.status === 500) {
      setToastErrorMessage(res?.data?.detail)
      setShowToastMessage(true);
    }
  };

  const resendOtpFun = () => {
    setShowErrorFlag(false);
    setResendOtpFlag(false);
    setShowTimerFlag(true);
    const res = resendOtp("+91" + mobileNumber);
    if (res?.status === 200) {
      setResendOtpFlag(false);
    }
    if (res?.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      navigate("/");
    }
    if (res?.status === 500) {
      setToastErrorMessage(res?.data?.detail)
      setShowToastMessage(true);
    }
}

  useEffect(() => {
    const handleAudio = async () => {
      const res = await generateSpeech(
        loginAudio[selectedLanguage],
        selectedLanguage
      );
      if (res?.data?.url) {
        const newAudio = new Audio(res?.data?.url);
        if (audioRef.current) {
          audioRef.current.pause();
        }
        if (requestController) {
          requestController.abort();
        }

        newAudio.addEventListener("ended", () => {
          setShowSpeakerActive(false);
          setDisableButtons(false);
        });

        audioRef.current = newAudio;
        newAudio.play().catch((err) => {
          console.error("Error playing audio:", err);
        });
      } else {
        setToastErrorMessage(res?.data?.detail)
        setShowToastMessage(true);
        setShowSpeakerActive(false);
      }
    };
    if (showSpeakerActive) handleAudio();
    setTimeout(() => {
      setDisableButtons(false);
    }, 9000);
  }, [showSpeakerActive]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };
    if (!mobileNumber) {
      navigate("/");
    }

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!loginConstants || !guideConstants) {
    return <div>Loading...</div>;
  }

  const { loginConstant, loginErrorLabels, loginErrorMap, loginAudio, getOtpSentLabel = () => {} } = loginConstants;
  const { guideButtons } = guideConstants;

  return (
    <>
      <div className="loginComponent">
        <div className="flex w-[100%] flex-col items-center justify-center px-4 pt-2">
          <div className="flex items-center justify-between w-full">
            <div className="loginNextBtn   ">
              <NextBtn
                icon={"/Assets/Images/arrowLeftBlack.svg"}
                nextClick={backArrowClick}
              />
            </div>
            <div
              className={`speakerDiv ${
                showSpeakerIcon && showSpeakerActive ? "Active" : ""
                }`}
              onClick={() => {
                setShowSpeakerActive(!showSpeakerActive);
                stopPlayingAudio(audioRef);
                setDisableButtons(false);
              }}
            >
              {showSpeakerIcon && showSpeakerActive
                ? guideButtons.stop[selectedLanguage]
                : null}
              {showSpeakerActive ? (
                <div className="">
                  <img
                    src={"/Assets/Images/blueSpeaker.svg"}
                    alt="active Speaker"
                  />
                </div>
              ) : (
                <div className="">
                  <img src={"/Assets/Images/crossSpeakerIcon.svg"} alt="speaker" />
                </div>
              )}
            </div>


          </div>
        </div>
        <div className="loginInputComponent">
          <div className="flex flex-col items-center justify-center">
            <img src={"/Assets/Images/otpPageIcon.svg"} alt="otpheadinglabel" />
            <div className="flex flex-col items-center justify-center w-[90%] pt-[2rem] pb-8">
              <div className="text-[2rem] text-black font-[600] pb-2">
                {loginConstant.otp_title[selectedLanguage]}
              </div>
              <div className="flex flex-col items-center">
                <div className="text-[1rem] text-gray-500 text-center">
                  {getOtpSentLabel(mobileNumber, selectedLanguage)}
                </div>
                <div>
                  {showTimerFlag && <CountdownTimer timerLength={timerLength} timeoutOccured={optTimeoutOccured} />}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex gap-1 items-center">
              <img src={"/Assets/Images/passwordIcon.svg"} alt="password" />
              <label>
                {loginConstant.pass[selectedLanguage]}{" "}
                {/* <span className="text-[var(--dark-neutral-2)]">
                  {loginConstant.passSpan[selectedLanguage]}
                </span> */}
              </label>
            </div>
            <input
              className={`inputfield focus:ring-blue-500 focus:border-blue-500 border ${
                showErrorFlag
                  ? "border-[var(--red-1)]"
                  : "border-[var(--dark-neutral-1)]"
                }`}
              type="tel"
              placeholder="00000"
              required
              maxLength={5}
              value={otp}
              onChange={handleOtpChange}
            />
            {showErrorFlag && (
              <div className="flex items-center text-[var(--red-1)]">
                <img src="./Assets/Images/alertCircle.svg" alt="alertcircle" />
                <span className="pl-2 text-lg">
                  {loginErrorLabels.otp[selectedLanguage]}
                </span>
              </div>
            )}
          </div>
        </div>
        {otp.length === 5 && (
          <div className="loginNextBtn">
            {!resendOtpFlag && <NextBtn
              value={loginConstant.proceedButton[selectedLanguage]}
              icon={"/Assets/Images/nextWhiteIcon.svg"}
              bgColor={"var(--primary-dark)"}
              color={"var(--neutral-white)"}
              nextClick={verifyOtpFun}
              padx={48}
            />}
            {resendOtpFlag && <NextBtn
              value={loginConstant.resend_otp[selectedLanguage]}
              icon={"/Assets/Images/nextWhiteIcon.svg"}
              bgColor={"var(--primary-dark)"}
              color={"var(--neutral-white)"}
              nextClick={resendOtpFun}
              padx={48}
            />}
          </div>
        )}
      </div>
      {
          showToastMessage && <ToastMessage message={toastErrorMessage} severity={ToastSeverityMap.error} direction={"bottom"} close={setShowToastMessage}/>
      }
    </>
  );
};

export default LoginComponent;
