import { FeedbackConstant } from "../../../Constants/AWW/FeedbackConstants";


const FeedbackChatBlock = ({ selectedLanguage, sendFeedback = () => { } }) => {
    return (
        <div className="flex items-center gap-3 mt-3">
            {
                FeedbackConstant.options.map((option, idx) => {
                    return (
                        <div key={idx} className={`flex items-center justify-center px-4 py-3 rounded-xl border-2 ${option.value === 'dislike' ? 'bg-[var(--red-3)] text-[var(--red-1)] border-[var(--red-2)]' : 'bg-[var(--green-3)] text-[var(--green-1)] border-[var(--green-2)]'}`} onClick={() => sendFeedback(option)}>
                            <img src={'./Assets/Images/' + option.image} alt="option" />
                            
                            <span className="text-lg ml-2">{option.label[selectedLanguage]}</span>
                        </div>

                    )
                })
            }
        </div>
    )
}

export default FeedbackChatBlock;