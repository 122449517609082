import { sourceConstant } from "../../../Constants/SourceDialogConstants";

const SourceDialog = ({
  selectedLanguage,
  sourceData,
  closeDialog = () => { },
}) => {

  const getPageString = (pagesArr) => {
    const pagesCopy = [...pagesArr];
    if (pagesCopy.length === 0) {
      return "";
    } else if (pagesCopy.length === 1) {
      return sourceConstant.page[selectedLanguage] + " " + pagesCopy[0];
    } else {
      const lastElement = pagesCopy.pop();
      return sourceConstant.page[selectedLanguage] + " " + pagesCopy.join(", ") + (selectedLanguage === "en" ? " and " : " और ") + lastElement;
    }
  };

  const openSourceLink = (source) => {
    if (selectedLanguage === "en") {
      window.open(source?.en_link, "_blank");
    } else if (selectedLanguage === "hi") {
      window.open(source?.hi_link, "_blank");
    }
  };
  return (
    <div>
      <div className="fixed inset-0 bg-gray-800 opacity-50"></div>
      <div className="fixed inset-0 flex items-center justify-center p-2">
        <div className="max-h-[80vh] h-fit relative bg-[var(--neutral-white)] p-6 rounded-lg shadow-xl w-[90%]">
          <div className="relative overflow-hidden h-full">
            <div className="flex flex-col items-center justify-center mb-4">
              <div className="text-[1rem] leading-9 font-[300]">
                {sourceConstant.subtitle[selectedLanguage]}
              </div>
            </div>
            <div className="overflow-y-auto max-h-[60vh] h-fit">
              {sourceData.map((source, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex items-center justify-between bg-[var(--light-neutral-2)] border-[var(--link-blue)] rounded-lg border-2 pl-4 pr-5 py-6 mb-4"
                    onClick={() => openSourceLink(source)}
                  >
                    <div className="flex flex-col justify-center items-start">
                      <div className="font-[300] text-[var(--dark-neutral-2)] text-lg">
                        {getPageString(source["page"])}
                      </div>
                      <div className="underline text-[var(--link-blue)] font-[600] text-xl">
                        {selectedLanguage === "en" && source?.en_source}
                        {selectedLanguage === "hi" && source?.hi_source}
                      </div>
                    </div>
                    <div>
                      <img
                        className="h-8"
                        src={"./Assets/Images/externalLink.svg"}
                        alt="link"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="absolute top-[-1.25rem] right-[-1.25rem] rounded-full bg-[var(--almost-black)] flex items-center justify-center p-1"
            onClick={closeDialog}
          >
            <img src={"./Assets/Images/crossIcon.svg"} alt="close" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SourceDialog;
