// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/Assets/Images/bgWallpaper.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginComponent {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    position: relative;
}

.loginAshabox {
    border-radius: 24px;
    padding: 6px 8px;
    width: 55%;
    background-color: var(--light-neutral-5);
    height: 80px;
}

.loginInputComponent {
    padding: 30px 32px 32px;
    display: flex;
    flex-direction: column;
}

.loginInputComponent label {
    font-family: "Noto Sans";
    font-weight: 500;
    letter-spacing: 0.15px;
    color: var(--black, #000);
    font-feature-settings: 'clig' off, 'liga' off;
}

.inputfield {
    border-radius: 4px;
    padding: 12px 16px;
}

.inputfield:active {
    border-color: var(--system-blue) !important;
}

.loginNextBtn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginSupport {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    text-decoration: underline;
    padding: 10px 20px;
    margin-top: 12px;
}`, "",{"version":3,"sources":["webpack://./src/Core/Components/Auth/LoginComponent/LoginComponent.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mDAAwD;IACxD,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,UAAU;IACV,wCAAwC;IACxC,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,wBAAwB;IACxB,gBAAgB;IAChB,sBAAsB;IACtB,yBAAyB;IACzB,6CAA6C;AACjD;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,0BAA0B;IAC1B,kBAAkB;IAClB,gBAAgB;AACpB","sourcesContent":[".loginComponent {\n    height: 100vh;\n    width: 100vw;\n    display: flex;\n    flex-direction: column;\n    background: url(\"/public/Assets/Images/bgWallpaper.svg\");\n    position: relative;\n}\n\n.loginAshabox {\n    border-radius: 24px;\n    padding: 6px 8px;\n    width: 55%;\n    background-color: var(--light-neutral-5);\n    height: 80px;\n}\n\n.loginInputComponent {\n    padding: 30px 32px 32px;\n    display: flex;\n    flex-direction: column;\n}\n\n.loginInputComponent label {\n    font-family: \"Noto Sans\";\n    font-weight: 500;\n    letter-spacing: 0.15px;\n    color: var(--black, #000);\n    font-feature-settings: 'clig' off, 'liga' off;\n}\n\n.inputfield {\n    border-radius: 4px;\n    padding: 12px 16px;\n}\n\n.inputfield:active {\n    border-color: var(--system-blue) !important;\n}\n\n.loginNextBtn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.loginSupport {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 4px;\n    text-decoration: underline;\n    padding: 10px 20px;\n    margin-top: 12px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
