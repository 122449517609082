import React from "react";
import BotChatBlock from "../../../Shared/Components/BotChatBlock/BotChatBlock";
import UserChatBlock from "../../../Shared/Components/UserChatBlock/UserChatBlock";
import LoadingChatBlock from "../../../Shared/Components/LoadingChatBlock/LoadingChatBlock";
import DateStamp from "../../../Shared/Components/DateStamp/DateStamp";
import Disclaimer from "../../../Shared/Components/Disclaimer/Disclaimer";
import { generateSpeech } from "../../../Actions/ChatActions";

const ChatQueueBlock = ({
  project,
  idx,
  followingIdx,
  selectedLanguage,
  isLoading,
  item,
  username,
  micStatus,
  date,
  speakerEnabled,
  playAudio = () => {},
  stopAudio = () => {},
  setSpeakerEnabled = () => {},
  openSourceDialog = () => {},
  sendFeedback = () => {},
  showDate,
  textSize,
  submitResponse = () => {},
  disabled,
  markFavourite = () => {},
  setToastErrorMessage = () => {},
  setShowToastMessage = () => {}
}) => {

  const getSpeechString = (title, options) => {
    let optionStr = "";
    if (options?.length > 0) {
      options.forEach((option) =>{
        optionStr = optionStr + " " + option[selectedLanguage]
      });
    }
    return title + optionStr 
  }

  const initSpeechSynth = async (index, title, options) => {
    if (!micStatus) {
      setSpeakerEnabled(index);
      const res = await generateSpeech(
        getSpeechString(title, options),
        selectedLanguage,
      );
      if (res?.data?.url) {
        playAudio(res?.data?.url);
      }
      else {
        setToastErrorMessage(res?.data?.detail)
        setShowToastMessage(true);
        setSpeakerEnabled(false);
      }
      
    }
  };

  return (
    <div className="block relative mb-2">
      {showDate && (
        <div className="flex flex-col items-center justify-center py-4 gap-2">
          <DateStamp dateValue={date} />
          <Disclaimer 
          project={project}
          selectedLanguage={selectedLanguage}
          />
        </div>
      )}
      <div className="flex flex-col">
        <div>
          {item && item["user"] && item["user"]["text"] && (
            <UserChatBlock
              selectedLanguage={selectedLanguage}
              text={item["user"]["text"]}
              image={item["user"]["image"]}
              textSize={textSize}
            />
          )}
        </div>
        <div className="relative">
          {isLoading && item.dummy ? (
            <LoadingChatBlock />
          ) : (
            item &&
            item["bot"] && (
              <BotChatBlock
                project={project}
                idx={idx}
                followingIdx={followingIdx}
                selectedLanguage={selectedLanguage}
                item={item}
                username={username}
                speakerEnabled={speakerEnabled}
                initSpeechSynth={initSpeechSynth}
                stopAudio={stopAudio}
                openSourceDialog={openSourceDialog}
                time={date.toLocaleTimeString("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                })}
                textSize={textSize}
                sendFeedback={sendFeedback}
                saveResponse={submitResponse}
                disabled={disabled}
                markFavourite={markFavourite}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatQueueBlock;
