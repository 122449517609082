import React, { useEffect, useState } from "react";
import { languageLabel } from "../../../Constants/Language-Constants";
import NextBtn from "../../../Shared/Components/NextBtn/NextBtn";
import "./Introduction.css";
import { generateSpeech } from "../../../Actions/ChatActions";
import { getConsentFlag, getOnboardingFlag, getUserToken, saveMobileNumber, stopPlayingAudio } from "../../../Actions/Utils";
import { useNavigate } from "react-router-dom";
import { verifyMobileNumber } from "../../../Actions/UserActions";
import { checkAPISuccess, CheckMobileNumber } from "../../../Actions/ErrorHandling";
import ToastMessage from "../../../Shared/Components/ToastMessage/ToastMessage";
import { ToastSeverityMap } from "../../../Constants/ToastConstants";
const hide_lang_button = process.env.REACT_APP_HIDE_LANG_BUTTON === 'true'; 

const Introduction = ({ project, selectedLanguage, setSelectedLanguage = () => {}, audioRef }) => {
  const navigate = useNavigate();
  const [showSpeakerIcon, setShowSpeakerIcon] = useState(false);
  const [showSpeakerActive, setShowSpeakerActive] = useState(false);
  const [requestController, setRequestController] = useState(null);
  const [constants, setConstants] = useState(null);
  const [loginConstants, setLoginConstants] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [showToastMessage, setShowToastMessage] = useState(false);
  const [toastErrorMessage, setToastErrorMessage] = useState("");


  const toggleLanguage = (e) => {
    setSelectedLanguage(e);
  };

  const nextClick = async () => {
    if (mobileNumber.length === 10) {
      saveMobileNumber(mobileNumber);
      try {
        const res = await verifyMobileNumber("+91"+mobileNumber);
        if (res?.status === 200) {
          if (res["data"]["verified"]) {
            navigate('/otp-verify');
          } else {
            navigate('/register');
          }
        }
        if(res?.status === 500) {
          setToastErrorMessage(res?.data?.detail)
          setShowToastMessage(true);
        }
      } catch (error) {
        console.error("Verification error:", error);
      }
    } else {
      alert('Please enter a valid 10-digit mobile number');
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value;
    // Check if the entered value is numeric and not exceeding 10 digits
    if (/^\d{0,10}$/.test(value)) {
      setMobileNumber(value);
    }
  };

  useEffect(() => {
    const handleAudio = async () => {
      const res = await generateSpeech(langPreferenceConstantGuide[selectedLanguage], selectedLanguage);
        if (res?.data?.url) {
            const newAudio = new Audio(res?.data?.url);
            if (audioRef.current) {
              audioRef.current.pause();
            }
            if (requestController) {
              requestController.abort();
            }

            newAudio.addEventListener("ended", () => {
              setShowSpeakerActive(false);
            });

            audioRef.current = newAudio;
            newAudio.play().catch((err) => {
              console.error("Error playing audio:", err);
            });
        }
        else {
          setToastErrorMessage(res?.data?.detail)
          setShowToastMessage(true);
          setShowSpeakerActive(false);
        }
      }
      if (showSpeakerActive) handleAudio();
  }, [showSpeakerActive]);


  useEffect(() => {
    const loadConstants = async () => {
      const module = await import(`../../../Constants/${project}/IntroductionConstants`);
      const loginModule = await import(`../../../Constants/${project}/LoginConstant`);
      setConstants(module);
      setLoginConstants(loginModule);
    };

    loadConstants();

    const handleVisibilityChange = () => {
      stopPlayingAudio(audioRef);
      setShowSpeakerActive(false);
    };

    if(!getUserToken()) {
      sessionStorage.clear();
      localStorage.clear();
    } else {
      if (!getOnboardingFlag()) {
        navigate("/guide");
      }
      else {
        if (!getConsentFlag()) {
          navigate("/consent");
        }
        else {
          navigate("/chat");
        }
      }
    }
    

    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    }
  }, []);

  if (!constants || !loginConstants) {
    return <div>Loading...</div>;
  }

  const { nextConstant, langPreferenceConstantGuide, guideButtons, title, subTitle } = constants;

  const { loginConstant } = loginConstants;

  return (
    <>
      <div
        className="bg-[#3237A0] h-screen w-screen relative bg-blend-color-burn"
        style={{ backgroundImage: `url("/Assets/Images/wallpaperBg.jpg")` }}
      >
        <div className="relative h-full flex flex-col items-center">
          <div className="w-full p-6 pb-2 flex justify-end ">
              <div 
                className={`speakerDiv ${
                  showSpeakerIcon && showSpeakerActive ? "Active" : ""
                }   `}
                onClick={() => {
                  setShowSpeakerActive(!showSpeakerActive);
                  stopPlayingAudio(audioRef);
                }}
              >
                {showSpeakerIcon && showSpeakerActive ? guideButtons.stop[selectedLanguage] : null}
                {showSpeakerActive ? (
                  <div className="">
                    <img
                      src={"/Assets/Images/blueSpeaker.svg"}
                      alt="active Speaker"
                    />
                  </div>
                ) : (
                  <div className="">
                    <img src={"/Assets/Images/crossSpeakerIcon.svg"} alt="speaker" />
                  </div>
                )}
              </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            { !hide_lang_button && (
            <div className="into-lang-buttons">
              <div
                className={`flex justify-center h-[100%] w-[50%] py-1.5 ${
                  selectedLanguage === "hi"
                    ? "introselectedLngBtn"
                    : "intronormalBtn"
                }`}
                onClick={() => toggleLanguage("hi")}
              >
                {languageLabel["hi"]}
              </div>
              <div
                className={`flex justify-center h-[100%] w-[50%] py-1.5  ${
                  selectedLanguage === "en"
                    ? "introselectedLngBtn"
                    : "intronormalBtn"
                }`}
                onClick={() => toggleLanguage("en")}
              >
                {languageLabel["en"]}
              </div>
            </div>
            )}
            <div className="flex flex-col items-center justify-center pt-[10%] text-[2rem]">
              <img src="/Assets/Images/asha_icon_square.svg" alt="asha" />
                <div className="font-bold text-white text-[2rem]">{title[selectedLanguage]["heading"]}</div>
                <div className="text-white">{title[selectedLanguage]["sub_heading"]}</div>
            </div>
            <div className="w-[80%] text-white text-center">{subTitle[selectedLanguage]}</div>
          </div>
          <div className="w-[80%] flex flex-col pt-10">
            <div className="flex gap-1.5 items-center">
              <img src={"/Assets/Images/mobileIcon.svg"} alt="mobile" />
              <label className="text-white">{loginConstant.mobile[selectedLanguage]}</label>
            </div>
            <input
              className="inputfield focus:ring-blue-500 focus:border-blue-500 border border-[var(--dark-neutral-1)]"
              autoFocus
              type="tel"
              placeholder="1234567890"
              required
              maxLength={10}
              value={mobileNumber}
              onChange={handleMobileChange}
            />
          </div>
          <div className="pt-4">
            {mobileNumber.length === 10 && <NextBtn
              // value={"Next"}
              value={nextConstant[selectedLanguage]}
              icon={"/Assets/Images/nextBlueIcon.svg"}
              bgColor={"var(--neutral-white)"}
              color={"var(--primary-dark)"}
              nextClick={nextClick}
            />}
          </div>
        </div>
      </div>
      {
        showToastMessage && <ToastMessage message={toastErrorMessage} severity={ToastSeverityMap.error} direction={"bottom"} close={setShowToastMessage}/>
      }
    </>
  );
};

export default Introduction;
