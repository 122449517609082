export const heading = {
    en:"Provide your basic information.",
    hi:"अपनी मुख्य जानकारी भरें।"
  }
export const secondaryHeading = {
    en:"Please fill in all the details.",
    hi:"कृपया सभी विवरण भरें"
  }

  export const form_headings = {
    name: { en: "Name", hi: "नाम" },
    role: { en: "Role", hi: "पद" },
    age: { en: "Age", hi: "उम्र" },
    gender: { en: "Gender", hi: "लिंग" },
    mobile: { en: "Mobile no.", hi: "मोबाइल नंबर" },
    state: { en: "State", hi: "राज्य" },
    district: { en: "District", hi: "डिस्ट्रिक्ट" },
    proceed: { en: "Fill the details to proceed", hi: "आगे  बढ़ने  के  लिए  सारी  जानकारी  भरें" },
    fill: { en: "Proceed", hi: "आगे बढ़े" },

  };
  export const formAudio = {
    "en": "Provide your basic information by filling in all the details. Then click on proceed to go forward.",
    "hi": "कृपया अपनी मुख्य जानकारी भरें। फिर आगे बढ़ने के लिए 'आगे बढ़ें' पर क्लिक करें।"
}


export const regsiterSuccess = {
    title: {
        en: "Registration Successful!",
        hi: "रजिस्ट्रेशन  सफल हुआ!"
    },
    description: {
        en: "You may proceed to login with your registered mobile number.",
        hi: "आप अपने रजिस्टर्ड मोबाइल नंबर से लॉगिन कर सकते हैं।"
    }
}

export const loginButtonLabel = {
    en: "Login",
    hi: "लॉगिन"
}

export const roleDropdownLabels = [
  {
    en: "Select your Role",
    hi: "अपना पद चुनें",
    value: ""
  },
  {
    en: "ASHA",
    hi: "आशा",
    value: "ASHA"
  },
  {
    en: "CHO",
    hi: "सी.एच.ओ",
    value: "CHO"
  },
];


export const genderDropdownLabels = [
  {
    en: "Select your gender",
    hi: "अपना लिंग चुनें",
    value: ""
  },
  {
    en: "Male",
    hi: "पुरुष",
    value: "Male"
  },
  {
    en: "Female",
    hi: "महिला",
    value: "Female"
  }
];

export const statePlaceholderLabel = {
    en: "Select your state",
    hi: "अपना राज्य चुनें",
}


export const stateDropdownLabels = [
    {
    "en": "Andaman And Nicobar Islands",
    "hi": "अंडमान और निकोबार द्वीप समूह"
    },
    {
    "en": "Andhra Pradesh",
    "hi": "आंध्र प्रदेश"
    },
    {
    "en": "Arunachal Pradesh",
    "hi": "अरुणाचल प्रदेश"
    },
    {
    "en": "Assam",
    "hi": "असम"
    },
    {
    "en": "Bihar",
    "hi": "बिहार"
    },
    {
    "en": "Chandigarh",
    "hi": "चंडीगढ़"
    },
    {
    "en": "Chhattisgarh",
    "hi": "छत्तीसगढ़"
    },
    {
    "en": "Delhi",
    "hi": "दिल्ली"
    },
    {
    "en": "Goa",
    "hi": "गोवा"
    },
    {
    "en": "Gujarat",
    "hi": "गुजरात"
    },
    {
    "en": "Haryana",
    "hi": "हरियाणा"
    },
    {
    "en": "Himachal Pradesh",
    "hi": "हिमाचल प्रदेश"
    },
    {
    "en": "Jammu And Kashmir",
    "hi": "जम्मू और कश्मीर"
    },
    {
    "en": "Jharkhand",
    "hi": "झारखंड"
    },
    {
    "en": "Karnataka",
    "hi": "कर्नाटक"
    },
    {
    "en": "Kerala",
    "hi": "केरल"
    },
    {
    "en": "Ladakh",
    "hi": "लद्दाख"
    },
    {
    "en": "Lakshadweep",
    "hi": "लक्षद्वीप"
    },
    {
    "en": "Madhya Pradesh",
    "hi": "मध्य प्रदेश"
    },
    {
    "en": "Maharashtra",
    "hi": "महाराष्ट्र"
    },
    {
    "en": "Manipur",
    "hi": "मणिपुर"
    },
    {
    "en": "Meghalaya",
    "hi": "मेघालय"
    },
    {
    "en": "Mizoram",
    "hi": "मिजोरम"
    },
    {
    "en": "Nagaland",
    "hi": "नागालैंड"
    },
    {
    "en": "Odisha",
    "hi": "ओडिशा"
    },
    {
    "en": "Puducherry",
    "hi": "पुडुचेरी"
    },
    {
    "en": "Punjab",
    "hi": "पंजाब"
    },
    {
    "en": "Rajasthan",
    "hi": "राजस्थान"
    },
    {
    "en": "Sikkim",
    "hi": "सिक्किम"
    },
    {
    "en": "Tamil Nadu",
    "hi": "तमिलनाडु"
    },
    {
    "en": "Telangana",
    "hi": "तेलंगाना"
    },
    {
    "en": "The Dadra And Nagar Haveli And Daman And Diu",
    "hi": "दादरा और नगर हवेली और दमन और दीव"
    },
    {
    "en": "Tripura",
    "hi": "त्रिपुरा"
    },
    {
    "en": "Uttarakhand",
    "hi": "उत्तराखंड"
    },
    {
    "en": "Uttar Pradesh",
    "hi": "उत्तर प्रदेश"
    },
    {
      en: "West Bengal",
      hi: "पश्चिम बंगाल",
    }
  ];  

export const districtPlaceholderLabel = {
    en: "Select your district",
    hi: "अपना जिला चुनें",
}

export const districtDropdownLabels = {
    "Andaman And Nicobar Islands": [
        {
            "en": "Nicobars",
            "hi": "निकोबार"
        },
        {
            "en": "North And Middle Andaman",
            "hi": "उत्तर और मध्य अंडमान"
        },
        {
            "en": "South Andamans",
            "hi": "दक्षिण अंडमान"
        }
    ],
    "Andhra Pradesh": [
        {
            "en": "Alluri Sitharama Raju",
            "hi": "अल्लूरी सीताराम राजू"
        },
        {
            "en": "Anakapalli",
            "hi": "अनकापल्ली"
        },
        {
            "en": "Ananthapuramu",
            "hi": "अनंतपुरमू"
        },
        {
            "en": "Annamayya",
            "hi": "अन्नमय्या"
        },
        {
            "en": "Bapatla",
            "hi": "बापटला"
        },
        {
            "en": "Chittoor",
            "hi": "चित्तूर"
        },
        {
            "en": "Dr. B.R. Ambedkar Konaseema",
            "hi": "डॉ. बी.आर. अम्बेडकर कोनसीमा"
        },
        {
            "en": "East Godavari",
            "hi": "पूर्वी गोदावरी"
        },
        {
            "en": "Eluru",
            "hi": "एलुरु"
        },
        {
            "en": "Guntur",
            "hi": "गुंटूर"
        },
        {
            "en": "Kakinada",
            "hi": "काकीनाडा"
        },
        {
            "en": "Krishna",
            "hi": "कृष्णा"
        },
        {
            "en": "Kurnool",
            "hi": "कुरनूल"
        },
        {
            "en": "Nandyal",
            "hi": "नंद्याल"
        },
        {
            "en": "Ntr",
            "hi": "एनटीआर"
        },
        {
            "en": "Palnadu",
            "hi": "पलनाडु"
        },
        {
            "en": "Parvathipuram Manyam",
            "hi": "पार्वतीपुरम मन्यम"
        },
        {
            "en": "Prakasam",
            "hi": "प्रकाशम"
        },
        {
            "en": "Srikakulam",
            "hi": "श्रीकाकुलम"
        },
        {
            "en": "Sri Potti Sriramulu Nellore",
            "hi": "श्री पोट्टि श्रीरामुलु नेल्लोर"
        },
        {
            "en": "Sri Sathya Sai",
            "hi": "श्री सत्य साई"
        },
        {
            "en": "Tirupati",
            "hi": "तिरुपति"
        },
        {
            "en": "Visakhapatnam",
            "hi": "विशाखापट्टनम"
        },
        {
            "en": "Vizianagaram",
            "hi": "विजयनगरम"
        },
        {
            "en": "West Godavari",
            "hi": "पश्चिमी गोदावरी"
        },
        {
            "en": "Y.S.R.",
            "hi": "वाई.एस.आर."
        }
    ],
    "Arunachal Pradesh": [
        {
            "en": "Anjaw",
            "hi": "अंजॉ"
        },
        {
            "en": "Changlang",
            "hi": "चांगलांग"
        },
        {
            "en": "Dibang Valley",
            "hi": "दिबांग घाटी"
        },
        {
            "en": "East Kameng",
            "hi": "पूर्वी कामेंग"
        },
        {
            "en": "East Siang",
            "hi": "पूर्वी सियांग"
        },
        {
            "en": "Kamle",
            "hi": "कामले"
        },
        {
            "en": "Kra Daadi",
            "hi": "क्रा दादी"
        },
        {
            "en": "Kurung Kumey",
            "hi": "कुरुंग कुमे"
        },
        {
            "en": "Leparada",
            "hi": "लेपरादा"
        },
        {
            "en": "Lohit",
            "hi": "लोहित"
        },
        {
            "en": "Longding",
            "hi": "लोंगडिंग"
        },
        {
            "en": "Lower Dibang Valley",
            "hi": "निचली दिबांग घाटी"
        },
        {
            "en": "Lower Siang",
            "hi": "निचली सियांग"
        },
        {
            "en": "Lower Subansiri",
            "hi": "निचली सुबनसिरी"
        },
        {
            "en": "Namsai",
            "hi": "नमसाई"
        },
        {
            "en": "Pakke Kessang",
            "hi": "पक्के केसांग"
        },
        {
            "en": "Papum Pare",
            "hi": "पापुम पारे"
        },
        {
            "en": "Shi Yomi",
            "hi": "शी योमी"
        },
        {
            "en": "Siang",
            "hi": "सियांग"
        },
        {
            "en": "Tawang",
            "hi": "तवांग"
        },
        {
            "en": "Tirap",
            "hi": "तिरप"
        },
        {
            "en": "Upper Siang",
            "hi": "ऊपरी सियांग"
        },
        {
            "en": "Upper Subansiri",
            "hi": "ऊपरी सुबनसिरी"
        },
        {
            "en": "West Kameng",
            "hi": "पश्चिमी कामेंग"
        },
        {
            "en": "West Siang",
            "hi": "पश्चिमी सियांग"
        }
    ],
    "Assam": [
        {
            "en": "Bajali",
            "hi": "बजाली"
        },
        {
            "en": "Baksa",
            "hi": "बक्सा"
        },
        {
            "en": "Barpeta",
            "hi": "बारपेटा"
        },
        {
            "en": "Biswanath",
            "hi": "बिस्वनाथ"
        },
        {
            "en": "Bongaigaon",
            "hi": "बोंगाईगांव"
        },
        {
            "en": "Cachar",
            "hi": "काछार"
        },
        {
            "en": "Charaideo",
            "hi": "चराइदेव"
        },
        {
            "en": "Chirang",
            "hi": "चिरांग"
        },
        {
            "en": "Darrang",
            "hi": "डरांग"
        },
        {
            "en": "Dhemaji",
            "hi": "धेमा"
        },
        {
            "en": "Dhubri",
            "hi": "धुबरी"
        },
        {
            "en": "Dibrugarh",
            "hi": "डिब्रूगढ़"
        },
        {
            "en": "Dima Hasao",
            "hi": "डिमा हसाओ"
        },
        {
            "en": "Goalpara",
            "hi": "गोआलपारा"
        },
        {
            "en": "Golaghat",
            "hi": "गोलाघाट"
        },
        {
            "en": "Hailakandi",
            "hi": "हैलाकांडी"
        },
        {
            "en": "Hojai",
            "hi": "होजाई"
        },
        {
            "en": "Jorhat",
            "hi": "जोरहाट"
        },
        {
            "en": "Kamrup",
            "hi": "कामरूप"
        },
        {
            "en": "Kamrup Metro",
            "hi": "कामरूप मेट्रो"
        },
        {
            "en": "Karbi Anglong",
            "hi": "कार्बी आंगलोंग"
        },
        {
            "en": "Karimganj",
            "hi": "करीमगंज"
        },
        {
            "en": "Kokrajhar",
            "hi": "कोकराझार"
        },
        {
            "en": "Lakhimpur",
            "hi": "लखीमपुर"
        },
        {
            "en": "Majuli",
            "hi": "माजुली"
        },
        {
            "en": "Marigaon",
            "hi": "मोरीगांव"
        },
        {
            "en": "Nagaon",
            "hi": "नगांव"
        },
        {
            "en": "Nalbari",
            "hi": "नलबाड़ी"
        },
        {
            "en": "Sivasagar",
            "hi": "शिवसागर"
        },
        {
            "en": "Sonitpur",
            "hi": "सोनितपुर"
        },
        {
            "en": "South Salmara Mancachar",
            "hi": "दक्षिण सलमारा मनकाचर"
        },
        {
            "en": "Tamulpur",
            "hi": "तमुलपुर"
        },
        {
            "en": "Tinsukia",
            "hi": "तिनसुकिया"
        },
        {
            "en": "Udalguri",
            "hi": "उदलगुरी"
        },
        {
            "en": "West Karbi Anglong",
            "hi": "पश्चिम कार्बी आंगलोंग"
        }
    ],
    "Bihar": [
        {
            "en": "Araria",
            "hi": "अररिया"
        },
        {
            "en": "Arwal",
            "hi": "अरवल"
        },
        {
            "en": "Aurangabad",
            "hi": "औरंगाबाद"
        },
        {
            "en": "Banka",
            "hi": "बांका"
        },
        {
            "en": "Begusarai",
            "hi": "बेगूसराय"
        },
        {
            "en": "Bhagalpur",
            "hi": "भागलपुर"
        },
        {
            "en": "Bhojpur",
            "hi": "भोजपुर"
        },
        {
            "en": "Buxar",
            "hi": "बक्सर"
        },
        {
            "en": "Darbhanga",
            "hi": "दरभंगा"
        },
        {
            "en": "Gaya",
            "hi": "गया"
        },
        {
            "en": "Gopalganj",
            "hi": "गोपालगंज"
        },
        {
            "en": "Jamui",
            "hi": "जमुई"
        },
        {
            "en": "Jehanabad",
            "hi": "जहानाबाद"
        },
        {
            "en": "Kaimur (Bhabua)",
            "hi": "कैमूर (भभुआ)"
        },
        {
            "en": "Katihar",
            "hi": "कटिहार"
        },
        {
            "en": "Khagaria",
            "hi": "खगड़िया"
        },
        {
            "en": "Kishanganj",
            "hi": "किशनगंज"
        },
        {
            "en": "Lakhisarai",
            "hi": "लखीसराय"
        },
        {
            "en": "Madhepura",
            "hi": "मधेपुरा"
        },
        {
            "en": "Madhubani",
            "hi": "मधुबनी"
        },
        {
            "en": "Munger",
            "hi": "मुंगेर"
        },
        {
            "en": "Muzaffarpur",
            "hi": "मुजफ्फरपुर"
        },
        {
            "en": "Nalanda",
            "hi": "नालंदा"
        },
        {
            "en": "Nawada",
            "hi": "नवादा"
        },
        {
            "en": "Pashchim Champaran",
            "hi": "पश्चिम चंपारण"
        },
        {
            "en": "Patna",
            "hi": "पटना"
        },
        {
            "en": "Purbi Champaran",
            "hi": "पूर्वी चंपारण"
        },
        {
            "en": "Purnia",
            "hi": "पूर्णिया"
        },
        {
            "en": "Rohtas",
            "hi": "रोहतास"
        },
        {
            "en": "Saharsa",
            "hi": "सहरसा"
        },
        {
            "en": "Samastipur",
            "hi": "समस्तीपुर"
        },
        {
            "en": "Saran",
            "hi": "सारण"
        },
        {
            "en": "Sheikhpura",
            "hi": "शेखपुरा"
        },
        {
            "en": "Sheohar",
            "hi": "शिवहर"
        },
        {
            "en": "Sitamarhi",
            "hi": "सीतामढ़ी"
        },
        {
            "en": "Siwan",
            "hi": "सीवान"
        },
        {
            "en": "Supaul",
            "hi": "सुपौल"
        },
        {
            "en": "Vaishali",
            "hi": "वैशाली"
        }
    ],
    "Chandigarh": [
        {
            "en": "Chandigarh",
            "hi": "चंडीगढ़"
        }
    ],
    "Chhattisgarh": [
        {
            "en": "Balod",
            "hi": "बालोद"
        },
        {
            "en": "Balodabazar-Bhatapara",
            "hi": "बलौदाबाजार-भाटापारा"
        },
        {
            "en": "Balrampur-Ramanujganj",
            "hi": "बलरामपुर-रामानुजगंज"
        },
        {
            "en": "Bastar",
            "hi": "बस्तर"
        },
        {
            "en": "Bemetara",
            "hi": "बेमेतरा"
        },
        {
            "en": "Bijapur",
            "hi": "बीजापुर"
        },
        {
            "en": "Bilaspur",
            "hi": "बिलासपुर"
        },
        {
            "en": "Dakshin Bastar Dantewada",
            "hi": "दक्षिण बस्तर दंतेवाड़ा"
        },
        {
            "en": "Dhamtari",
            "hi": "धमतरी"
        },
        {
            "en": "Durg",
            "hi": "दुर्ग"
        },
        {
            "en": "Gariyaband",
            "hi": "गरियाबंद"
        },
        {
            "en": "Gaurela-Pendra-Marwahi",
            "hi": "गौरेला-पेंड्रा-मरवाही"
        },
        {
            "en": "Janjgir-Champa",
            "hi": "जांजगीर-चांपा"
        },
        {
            "en": "Jashpur",
            "hi": "जशपुर"
        },
        {
            "en": "Kabeerdham",
            "hi": "कबीरधाम"
        },
        {
            "en": "Khairagarh-Chhuikhadan-Gandai",
            "hi": "खैरागढ़-छुईखदान-गंडई"
        },
        {
            "en": "Kondagaon",
            "hi": "कोंडागांव"
        },
        {
            "en": "Korba",
            "hi": "कोरबा"
        },
        {
            "en": "Korea",
            "hi": "कोरिया"
        },
        {
            "en": "Mahasamund",
            "hi": "महासमुंद"
        },
        {
            "en": "Manendragarh-Chirmiri-Bharatpur(M C B)",
            "hi": "मनेंद्रगढ़-चिरमिरी-भारतपुर"
        },
        {
            "en": "Mohla-Manpur-Ambagarh Chouki",
            "hi": "मोहला-मानपुर-अंबागढ़ चौकी"
        },
        {
            "en": "Mungeli",
            "hi": "मुंगेली"
        },
        {
            "en": "Narayanpur",
            "hi": "नारायणपुर"
        },
        {
            "en": "Raigarh",
            "hi": "रायगढ़"
        },
        {
            "en": "Raipur",
            "hi": "रायपुर"
        },
        {
            "en": "Rajnandgaon",
            "hi": "राजनांदगांव"
        },
        {
            "en": "Sakti",
            "hi": "शक्ति"
        },
        {
            "en": "Sarangarh-Bilaigarh",
            "hi": "सरगुजा-बिलाईगढ़"
        },
        {
            "en": "Sukma",
            "hi": "सुकमा"
        },
        {
            "en": "Surajpur",
            "hi": "सूरजपुर"
        },
        {
            "en": "Surguja",
            "hi": "सुरगुजा"
        },
        {
            "en": "Uttar Bastar Kanker",
            "hi": "उत्तर बस्तर कांकेर"
        }
    ],
    "Delhi": [
        {
            "en": "Central",
            "hi": "मध्य"
        },
        {
            "en": "East",
            "hi": "पूर्व"
        },
        {
            "en": "New Delhi",
            "hi": "नई दिल्ली"
        },
        {
            "en": "North",
            "hi": "उत्तर"
        },
        {
            "en": "North East",
            "hi": "उत्तर पूर्वी"
        },
        {
            "en": "North West",
            "hi": "उत्तर पश्चिमी"
        },
        {
            "en": "Shahdara",
            "hi": "शाहदरा"
        },
        {
            "en": "South",
            "hi": "दक्षिण"
        },
        {
            "en": "South East",
            "hi": "दक्षिण पूर्वी"
        },
        {
            "en": "South West",
            "hi": "दक्षिण पश्चिमी"
        },
        {
            "en": "West",
            "hi": "पश्चिम"
        }
    ],
    "Goa": [
        {
            "en": "North Goa",
            "hi": "उत्तर गोवा"
        },
        {
            "en": "South Goa",
            "hi": "दक्षिण गोवा"
        }
    ],
    "Gujarat": [
        {
            "en": "Ahmedabad",
            "hi": "अहमदाबाद"
        },
        {
            "en": "Amreli",
            "hi": "अमरेली"
        },
        {
            "en": "Anand",
            "hi": "आणंद"
        },
        {
            "en": "Arvalli",
            "hi": "अरावली"
        },
        {
            "en": "Banas Kantha",
            "hi": "बनासकांठा"
        },
        {
            "en": "Bharuch",
            "hi": "भरूच"
        },
        {
            "en": "Bhavnagar",
            "hi": "भावनगर"
        },
        {
            "en": "Botad",
            "hi": "बोटाद"
        },
        {
            "en": "Chhotaudepur",
            "hi": "छोटा उदयपुर"
        },
        {
            "en": "Dahod",
            "hi": "दाहोद"
        },
        {
            "en": "Dangs",
            "hi": "डांग्स"
        },
        {
            "en": "Devbhumi Dwarka",
            "hi": "देवभूमि द्वारका"
        },
        {
            "en": "Gandhinagar",
            "hi": "गांधीनगर"
        },
        {
            "en": "Gir Somnath",
            "hi": "गिर सोमनाथ"
        },
        {
            "en": "Jamnagar",
            "hi": "जामनगर"
        },
        {
            "en": "Junagadh",
            "hi": "जूनागढ़"
        },
        {
            "en": "Kachchh",
            "hi": "कच्छ"
        },
        {
            "en": "Kheda",
            "hi": "खेड़ा"
        },
        {
            "en": "Mahesana",
            "hi": "मेहसाणा"
        },
        {
            "en": "Mahisagar",
            "hi": "महीसागर"
        },
        {
            "en": "Morbi",
            "hi": "मोरबी"
        },
        {
            "en": "Narmada",
            "hi": "नर्मदा"
        },
        {
            "en": "Navsari",
            "hi": "नवसारी"
        },
        {
            "en": "Panch Mahals",
            "hi": "पंच महल"
        },
        {
            "en": "Patan",
            "hi": "पाटन"
        },
        {
            "en": "Porbandar",
            "hi": "पोरबंदर"
        },
        {
            "en": "Rajkot",
            "hi": "राजकोट"
        },
        {
            "en": "Sabar Kantha",
            "hi": "साबरकांठा"
        },
        {
            "en": "Surat",
            "hi": "सूरत"
        },
        {
            "en": "Surendranagar",
            "hi": "सुरेंद्रनगर"
        },
        {
            "en": "Tapi",
            "hi": "तापी"
        },
        {
            "en": "Vadodara",
            "hi": "वडोदरा"
        },
        {
            "en": "Valsad",
            "hi": "वलसाड"
        }
    ],
    "Haryana": [
        {
            "en": "Ambala",
            "hi": "अंबाला"
        },
        {
            "en": "Bhiwani",
            "hi": "भिवानी"
        },
        {
            "en": "Charki Dadri",
            "hi": "चरखी दादरी"
        },
        {
            "en": "Faridabad",
            "hi": "फरीदाबाद"
        },
        {
            "en": "Fatehabad",
            "hi": "फतेहाबाद"
        },
        {
            "en": "Gurugram",
            "hi": "गुरुग्राम"
        },
        {
            "en": "Hisar",
            "hi": "हिसार"
        },
        {
            "en": "Jhajjar",
            "hi": "झज्जर"
        },
        {
            "en": "Jind",
            "hi": "जींद"
        },
        {
            "en": "Kaithal",
            "hi": "कैथल"
        },
        {
            "en": "Karnal",
            "hi": "करनाल"
        },
        {
            "en": "Kurukshetra",
            "hi": "कुरुक्षेत्र"
        },
        {
            "en": "Mahendragarh",
            "hi": "महेंद्रगढ़"
        },
        {
            "en": "Nuh",
            "hi": "नूंह"
        },
        {
            "en": "Palwal",
            "hi": "पलवल"
        },
        {
            "en": "Panchkula",
            "hi": "पंचकूला"
        },
        {
            "en": "Panipat",
            "hi": "पानीपत"
        },
        {
            "en": "Rewari",
            "hi": "रेवाड़ी"
        },
        {
            "en": "Rohtak",
            "hi": "रोहतक"
        },
        {
            "en": "Sirsa",
            "hi": "सिरसा"
        },
        {
            "en": "Sonipat",
            "hi": "सोनीपत"
        },
        {
            "en": "Yamunanagar",
            "hi": "यमुनानगर"
        }
    ],
    "Himachal Pradesh": [
        {
            "en": "Bilaspur",
            "hi": "बिलासपुर"
        },
        {
            "en": "Chamba",
            "hi": "चंबा"
        },
        {
            "en": "Hamirpur",
            "hi": "हमीरपुर"
        },
        {
            "en": "Kangra",
            "hi": "कांगड़ा"
        },
        {
            "en": "Kinnaur",
            "hi": "किन्नौर"
        },
        {
            "en": "Kullu",
            "hi": "कुल्लू"
        },
        {
            "en": "Lahaul And Spiti",
            "hi": "लाहौल और स्पीति"
        },
        {
            "en": "Mandi",
            "hi": "मंडी"
        },
        {
            "en": "Shimla",
            "hi": "शिमला"
        },
        {
            "en": "Sirmaur",
            "hi": "सिरमौर"
        },
        {
            "en": "Solan",
            "hi": "सोलन"
        },
        {
            "en": "Una",
            "hi": "ऊना"
        }
    ],
    "Jammu And Kashmir": [
        {
            "en": "Anantnag",
            "hi": "अनंतनाग"
        },
        {
            "en": "Bandipora",
            "hi": "बांदीपोरा"
        },
        {
            "en": "Baramulla",
            "hi": "बारामूला"
        },
        {
            "en": "Budgam",
            "hi": "बडगाम"
        },
        {
            "en": "Doda",
            "hi": "डोडा"
        },
        {
            "en": "Ganderbal",
            "hi": "गांदरबल"
        },
        {
            "en": "Jammu",
            "hi": "जम्मू"
        },
        {
            "en": "Kathua",
            "hi": "कठुआ"
        },
        {
            "en": "Kishtwar",
            "hi": "किश्तवाड़"
        },
        {
            "en": "Kulgam",
            "hi": "कुलगाम"
        },
        {
            "en": "Kupwara",
            "hi": "कुपवाड़ा"
        },
        {
            "en": "Poonch",
            "hi": "पुंछ"
        },
        {
            "en": "Pulwama",
            "hi": "पुलवामा"
        },
        {
            "en": "Rajouri",
            "hi": "राजौरी"
        },
        {
            "en": "Ramban",
            "hi": "रामबन"
        },
        {
            "en": "Reasi",
            "hi": "रीासी"
        },
        {
            "en": "Samba",
            "hi": "सांबा"
        },
        {
            "en": "Shopian",
            "hi": "शोपियां"
        },
        {
            "en": "Srinagar",
            "hi": "श्रीनगर"
        },
        {
            "en": "Udhampur",
            "hi": "उधमपुर"
        }
    ],
    "Jharkhand": [
        {
            "en": "Bokaro",
            "hi": "बोकारो"
        },
        {
            "en": "Chatra",
            "hi": "चतरा"
        },
        {
            "en": "Deoghar",
            "hi": "देवघर"
        },
        {
            "en": "Dhanbad",
            "hi": "धनबाद"
        },
        {
            "en": "Dumka",
            "hi": "दुमका"
        },
        {
            "en": "East Singhbum",
            "hi": "पूर्वी सिंहभूम"
        },
        {
            "en": "Garhwa",
            "hi": "गढ़वा"
        },
        {
            "en": "Giridih",
            "hi": "गिरिडीह"
        },
        {
            "en": "Godda",
            "hi": "गोड्डा"
        },
        {
            "en": "Gumla",
            "hi": "गुमला"
        },
        {
            "en": "Hazaribagh",
            "hi": "हजारीबाग"
        },
        {
            "en": "Jamtara",
            "hi": "जामताड़ा"
        },
        {
            "en": "Khunti",
            "hi": "खूंटी"
        },
        {
            "en": "Koderma",
            "hi": "कोडरमा"
        },
        {
            "en": "Latehar",
            "hi": "लातेहार"
        },
        {
            "en": "Lohardaga",
            "hi": "लोहरदगा"
        },
        {
            "en": "Pakur",
            "hi": "पाकुड़"
        },
        {
            "en": "Palamu",
            "hi": "पलामू"
        },
        {
            "en": "Ramgarh",
            "hi": "रामगढ़"
        },
        {
            "en": "Ranchi",
            "hi": "रांची"
        },
        {
            "en": "Sahebganj",
            "hi": "साहेबगंज"
        },
        {
            "en": "Saraikela Kharsawan",
            "hi": "सरायकेला खरसावां"
        },
        {
            "en": "Simdega",
            "hi": "सिमडेगा"
        },
        {
            "en": "West Singhbhum",
            "hi": "पश्चिमी सिंहभूम"
        }
    ],
    "Karnataka": [
        {
            "en": "Bagalkote",
            "hi": "बागलकोट"
        },
        {
            "en": "Ballari",
            "hi": "बल्लारी"
        },
        {
            "en": "Belagavi",
            "hi": "बेलगावी"
        },
        {
            "en": "Bengaluru Rural",
            "hi": "बेंगलुरु ग्रामीण"
        },
        {
            "en": "Bengaluru Urban",
            "hi": "बेंगलुरु शहरी"
        },
        {
            "en": "Bidar",
            "hi": "बिदर"
        },
        {
            "en": "Chamarajanagara",
            "hi": "चामराजनगर"
        },
        {
            "en": "Chikkaballapura",
            "hi": "चिक्कबल्लापुर"
        },
        {
            "en": "Chikkamagaluru",
            "hi": "चिक्कमगलुरु"
        },
        {
            "en": "Chitradurga",
            "hi": "चित्रदुर्ग"
        },
        {
            "en": "Dakshina Kannada",
            "hi": "दक्षिण कन्नड़"
        },
        {
            "en": "Davangere",
            "hi": "दावणगेरे"
        },
        {
            "en": "Dharwad",
            "hi": "धारवाड़"
        },
        {
            "en": "Gadag",
            "hi": "गडग"
        },
        {
            "en": "Hassan",
            "hi": "हसन"
        },
        {
            "en": "Haveri",
            "hi": "हावेरी"
        },
        {
            "en": "Kalaburagi",
            "hi": "कलबुर्गी"
        },
        {
            "en": "Kodagu",
            "hi": "कोडागु"
        },
        {
            "en": "Kolar",
            "hi": "कोलार"
        },
        {
            "en": "Koppal",
            "hi": "कोप्पल"
        },
        {
            "en": "Mandya",
            "hi": "मांड्या"
        },
        {
            "en": "Mysuru",
            "hi": "मैसूरु"
        },
        {
            "en": "Raichur",
            "hi": "रायचूर"
        },
        {
            "en": "Ramanagara",
            "hi": "रामनगर"
        },
        {
            "en": "Shivamogga",
            "hi": "शिवमोग्गा"
        },
        {
            "en": "Tumakuru",
            "hi": "तुमकुरु"
        },
        {
            "en": "Udupi",
            "hi": "उडुपी"
        },
        {
            "en": "Uttara Kannada",
            "hi": "उत्तर कन्नड़"
        },
        {
            "en": "Vijayanagar",
            "hi": "विजयनगर"
        },
        {
            "en": "Vijayapura",
            "hi": "विजयपुरा"
        },
        {
            "en": "Yadgir",
            "hi": "यादगीर"
        }
    ],
    "Kerala": [
        {
            "en": "Alappuzha",
            "hi": "अलाप्पुझा"
        },
        {
            "en": "Ernakulam",
            "hi": "एर्नाकुलम"
        },
        {
            "en": "Idukki",
            "hi": "इडुक्की"
        },
        {
            "en": "Kannur",
            "hi": "कन्नूर"
        },
        {
            "en": "Kasaragod",
            "hi": "कासरगोड"
        },
        {
            "en": "Kollam",
            "hi": "कोल्लम"
        },
        {
            "en": "Kottayam",
            "hi": "कोट्टायम"
        },
        {
            "en": "Kozhikode",
            "hi": "कोझिकोड"
        },
        {
            "en": "Malappuram",
            "hi": "मलप्पुरम"
        },
        {
            "en": "Palakkad",
            "hi": "पालक्काड"
        },
        {
            "en": "Pathanamthitta",
            "hi": "पथानामथिट्टा"
        },
        {
            "en": "Thiruvananthapuram",
            "hi": "तिरुवनंतपुरम"
        },
        {
            "en": "Thrissur",
            "hi": "त्रिशूर"
        },
        {
            "en": "Wayanad",
            "hi": "वायनाड"
        }
    ],
    "Ladakh": [
        {
            "en": "Kargil",
            "hi": "कारगिल"
        },
        {
            "en": "Leh Ladakh",
            "hi": "लेह लद्दाख"
        }
    ],
    "Lakshadweep": [
        {
            "en": "Lakshadweep District",
            "hi": "लक्षद्वीप जिला"
        }
    ],
    "Madhya Pradesh": [
        {
            "en": "Agar-Malwa",
            "hi": "आगर-मालवा"
        },
        {
            "en": "Alirajpur",
            "hi": "अलीराजपुर"
        },
        {
            "en": "Anuppur",
            "hi": "अनूपपुर"
        },
        {
            "en": "Ashoknagar",
            "hi": "अशोकनगर"
        },
        {
            "en": "Balaghat",
            "hi": "बालाघाट"
        },
        {
            "en": "Barwani",
            "hi": "बड़वानी"
        },
        {
            "en": "Betul",
            "hi": "बेतूल"
        },
        {
            "en": "Bhind",
            "hi": "भिंड"
        },
        {
            "en": "Bhopal",
            "hi": "भोपाल"
        },
        {
            "en": "Burhanpur",
            "hi": "बुरहानपुर"
        },
        {
            "en": "Chhatarpur",
            "hi": "छतरपुर"
        },
        {
            "en": "Chhindwara",
            "hi": "छिंदवाड़ा"
        },
        {
            "en": "Damoh",
            "hi": "दमोह"
        },
        {
            "en": "Datia",
            "hi": "दतिया"
        },
        {
            "en": "Dewas",
            "hi": "देवास"
        },
        {
            "en": "Dhar",
            "hi": "धार"
        },
        {
            "en": "Dindori",
            "hi": "डिंडोरी"
        },
        {
            "en": "Guna",
            "hi": "गुना"
        },
        {
            "en": "Gwalior",
            "hi": "ग्वालियर"
        },
        {
            "en": "Harda",
            "hi": "हरदा"
        },
        {
            "en": "Indore",
            "hi": "इंदौर"
        },
        {
            "en": "Jabalpur",
            "hi": "जबलपुर"
        },
        {
            "en": "Jhabua",
            "hi": "झाबुआ"
        },
        {
            "en": "Katni",
            "hi": "कटनी"
        },
        {
            "en": "Khandwa (East Nimar)",
            "hi": "खंडवा (पूर्वी निमाड़)"
        },
        {
            "en": "Khargone (West Nimar)",
            "hi": "खरगोन (पश्चिमी निमाड़)"
        },
        {
            "en": "Maihar",
            "hi": "मैहर"
        },
        {
            "en": "Mandla",
            "hi": "मंडला"
        },
        {
            "en": "Mandsaur",
            "hi": "मंदसौर"
        },
        {
            "en": "MAUGANJ",
            "hi": "मऊगंज"
        },
        {
            "en": "Morena",
            "hi": "मुरैना"
        },
        {
            "en": "Narmadapuram",
            "hi": "नर्मदापुरम"
        },
        {
            "en": "Narsimhapur",
            "hi": "नरसिंहपुर"
        },
        {
            "en": "Neemuch",
            "hi": "नीमच"
        },
        {
            "en": "Niwari",
            "hi": "निवाड़ी"
        },
        {
            "en": "Pandhurna",
            "hi": "पांढुर्ना"
        },
        {
            "en": "Panna",
            "hi": "पन्ना"
        },
        {
            "en": "Raisen",
            "hi": "रायसेन"
        },
        {
            "en": "Rajgarh",
            "hi": "राजगढ़"
        },
        {
            "en": "Ratlam",
            "hi": "रतलाम"
        },
        {
            "en": "Rewa",
            "hi": "रीवा"
        },
        {
            "en": "Sagar",
            "hi": "सागर"
        },
        {
            "en": "Satna",
            "hi": "सतना"
        },
        {
            "en": "Sehore",
            "hi": "सीहोर"
        },
        {
            "en": "Seoni",
            "hi": "सिवनी"
        },
        {
            "en": "Shahdol",
            "hi": "शहडोल"
        },
        {
            "en": "Shajapur",
            "hi": "शाजापुर"
        },
        {
            "en": "Sheopur",
            "hi": "श्योपुर"
        },
        {
            "en": "Shivpuri",
            "hi": "शिवपुरी"
        },
        {
            "en": "Sidhi",
            "hi": "सीधी"
        },
        {
            "en": "Singrauli",
            "hi": "सिंगरौली"
        },
        {
            "en": "Tikamgarh",
            "hi": "टीकमगढ़"
        },
        {
            "en": "Ujjain",
            "hi": "उज्जैन"
        },
        {
            "en": "Umaria",
            "hi": "उमरिया"
        },
        {
            "en": "Vidisha",
            "hi": "विदिशा"
        }
    ],
    "Maharashtra": [
        {
            "en": "Ahmednagar",
            "hi": "अहमदनगर"
        },
        {
            "en": "Akola",
            "hi": "अकोला"
        },
        {
            "en": "Amravati",
            "hi": "अमरावती"
        },
        {
            "en": "Beed",
            "hi": "बीड"
        },
        {
            "en": "Bhandara",
            "hi": "भंडारा"
        },
        {
            "en": "Buldhana",
            "hi": "बुलढाणा"
        },
        {
            "en": "Chandrapur",
            "hi": "चंद्रपुर"
        },
        {
            "en": "Chhatrapati Sambhajinagar",
            "hi": "छत्रपति संभाजीनगर"
        },
        {
            "en": "Dharashiv",
            "hi": "धाराशिव"
        },
        {
            "en": "Dhule",
            "hi": "धुले"
        },
        {
            "en": "Gadchiroli",
            "hi": "गड़चिरोली"
        },
        {
            "en": "Gondia",
            "hi": "गोंदिया"
        },
        {
            "en": "Hingoli",
            "hi": "हिंगोली"
        },
        {
            "en": "Jalgaon",
            "hi": "जलगांव"
        },
        {
            "en": "Jalna",
            "hi": "जालना"
        },
        {
            "en": "Kolhapur",
            "hi": "कोल्हापुर"
        },
        {
            "en": "Latur",
            "hi": "लातूर"
        },
        {
            "en": "Mumbai",
            "hi": "मुंबई"
        },
        {
            "en": "Mumbai Suburban",
            "hi": "मुंबई उपनगर"
        },
        {
            "en": "Nagpur",
            "hi": "नागपुर"
        },
        {
            "en": "Nanded",
            "hi": "नांदेड"
        },
        {
            "en": "Nandurbar",
            "hi": "नंदुरबार"
        },
        {
            "en": "Nashik",
            "hi": "नाशिक"
        },
        {
            "en": "Palghar",
            "hi": "पालघर"
        },
        {
            "en": "Parbhani",
            "hi": "परभणी"
        },
        {
            "en": "Pune",
            "hi": "पुणे"
        },
        {
            "en": "Raigad",
            "hi": "रायगढ़"
        },
        {
            "en": "Ratnagiri",
            "hi": "रत्नागिरी"
        },
        {
            "en": "Sangli",
            "hi": "सांगली"
        },
        {
            "en": "Satara",
            "hi": "सातारा"
        },
        {
            "en": "Sindhudurg",
            "hi": "सिंधुदुर्ग"
        },
        {
            "en": "Solapur",
            "hi": "सोलापुर"
        },
        {
            "en": "Thane",
            "hi": "ठाणे"
        },
        {
            "en": "Wardha",
            "hi": "वर्धा"
        },
        {
            "en": "Washim",
            "hi": "वाशिम"
        },
        {
            "en": "Yavatmal",
            "hi": "यवतमाल"
        }
    ],
    "Manipur": [
        {
            "en": "Bishnupur",
            "hi": "बिष्णुपुर"
        },
        {
            "en": "Chandel",
            "hi": "चंदेल"
        },
        {
            "en": "Churachandpur",
            "hi": "चुराचांदपुर"
        },
        {
            "en": "Imphal East",
            "hi": "इंफाल पूर्वी"
        },
        {
            "en": "Imphal West",
            "hi": "इंफाल पश्चिमी"
        },
        {
            "en": "Jiribam",
            "hi": "जीरीबाम"
        },
        {
            "en": "Kakching",
            "hi": "ककचिंग"
        },
        {
            "en": "Kamjong",
            "hi": "कामजोंग"
        },
        {
            "en": "Kangpokpi",
            "hi": "कांगपोकपी"
        },
        {
            "en": "Noney",
            "hi": "नोनी"
        },
        {
            "en": "Pherzawl",
            "hi": "फेरजॉल"
        },
        {
            "en": "Senapati",
            "hi": "सेनापति"
        },
        {
            "en": "Tamenglong",
            "hi": "तमेंगलोंग"
        },
        {
            "en": "Tengnoupal",
            "hi": "तेंगनौपाल"
        },
        {
            "en": "Thoubal",
            "hi": "थौबल"
        },
        {
            "en": "Ukhrul",
            "hi": "उखरुल"
        }
    ],
    "Meghalaya": [
        {
            "en": "Eastern West Khasi Hills",
            "hi": "पूर्वी पश्चिम खासी हिल्स"
        },
        {
            "en": "East Garo Hills",
            "hi": "पूर्वी गारो हिल्स"
        },
        {
            "en": "East Jaintia Hills",
            "hi": "पूर्वी जयंतिया हिल्स"
        },
        {
            "en": "East Khasi Hills",
            "hi": "पूर्वी खासी हिल्स"
        },
        {
            "en": "North Garo Hills",
            "hi": "उत्तर गारो हिल्स"
        },
        {
            "en": "Ri Bhoi",
            "hi": "री भोई"
        },
        {
            "en": "South Garo Hills",
            "hi": "दक्षिण गारो हिल्स"
        },
        {
            "en": "South West Garo Hills",
            "hi": "दक्षिण पश्चिम गारो हिल्स"
        },
        {
            "en": "South West Khasi Hills",
            "hi": "दक्षिण पश्चिम खासी हिल्स"
        },
        {
            "en": "West Garo Hills",
            "hi": "पश्चिम गारो हिल्स"
        },
        {
            "en": "West Jaintia Hills",
            "hi": "पश्चिम जयंतिया हिल्स"
        },
        {
            "en": "West Khasi Hills",
            "hi": "पश्चिम खासी हिल्स"
        }
    ],
    "Mizoram": [
        {
            "en": "Aizawl",
            "hi": "आइजोल"
        },
        {
            "en": "Champhai",
            "hi": "चम्फाई"
        },
        {
            "en": "Hnahthial",
            "hi": "ह्नाहथियाल"
        },
        {
            "en": "Khawzawl",
            "hi": "खवजावल"
        },
        {
            "en": "Kolasib",
            "hi": "कोलासिब"
        },
        {
            "en": "Lawngtlai",
            "hi": "लॉंगतलाई"
        },
        {
            "en": "Lunglei",
            "hi": "लुंगलेई"
        },
        {
            "en": "Mamit",
            "hi": "ममित"
        },
        {
            "en": "Saitual",
            "hi": "सैतुअल"
        },
        {
            "en": "Serchhip",
            "hi": "सेरछिप"
        },
        {
            "en": "Siaha",
            "hi": "सियाहा"
        }
    ],
    "Nagaland": [
        {
            "en": "Chumoukedima",
            "hi": "चुमौकेडिमा"
        },
        {
            "en": "Dimapur",
            "hi": "दीमापुर"
        },
        {
            "en": "Kiphire",
            "hi": "किफिरे"
        },
        {
            "en": "Kohima",
            "hi": "कोहिमा"
        },
        {
            "en": "Longleng",
            "hi": "लोंगलेनग"
        },
        {
            "en": "Mokokchung",
            "hi": "मोकोकचुंग"
        },
        {
            "en": "Mon",
            "hi": "मोन"
        },
        {
            "en": "Niuland",
            "hi": "निउलैंड"
        },
        {
            "en": "Noklak",
            "hi": "नोकलक"
        },
        {
            "en": "Peren",
            "hi": "पेरेन"
        },
        {
            "en": "Phek",
            "hi": "फेक"
        },
        {
            "en": "Shamator",
            "hi": "शामटोर"
        },
        {
            "en": "Tseminyu",
            "hi": "त्सेमिन्यु"
        },
        {
            "en": "Tuensang",
            "hi": "तुएनसांग"
        },
        {
            "en": "Wokha",
            "hi": "वोखा"
        },
        {
            "en": "Zunheboto",
            "hi": "जुन्हेबोटो"
        }
    ],
    "Odisha": [
        {
            "en": "Anugul",
            "hi": "अंगुल"
        },
        {
            "en": "Balangir",
            "hi": "बलांगीर"
        },
        {
            "en": "Baleshwar",
            "hi": "बालेश्वर"
        },
        {
            "en": "Bargarh",
            "hi": "बड़गड़"
        },
        {
            "en": "Bhadrak",
            "hi": "भद्रक"
        },
        {
            "en": "Boudh",
            "hi": "बौध"
        },
        {
            "en": "Cuttack",
            "hi": "कटक"
        },
        {
            "en": "Deogarh",
            "hi": "देवगढ़"
        },
        {
            "en": "Dhenkanal",
            "hi": "ढेंकानाल"
        },
        {
            "en": "Gajapati",
            "hi": "गजपति"
        },
        {
            "en": "Ganjam",
            "hi": "गंजाम"
        },
        {
            "en": "Jagatsinghapur",
            "hi": "जगतसिंहपुर"
        },
        {
            "en": "Jajapur",
            "hi": "जाजपुर"
        },
        {
            "en": "Jharsuguda",
            "hi": "झारसुगुड़ा"
        },
        {
            "en": "Kalahandi",
            "hi": "कालाहांडी"
        },
        {
            "en": "Kandhamal",
            "hi": "कंधमाल"
        },
        {
            "en": "Kendrapara",
            "hi": "केंद्रापाड़ा"
        },
        {
            "en": "Kendujhar",
            "hi": "केंद्रुजहर"
        },
        {
            "en": "Khordha",
            "hi": "खोर्धा"
        },
        {
            "en": "Koraput",
            "hi": "कोरापुट"
        },
        {
            "en": "Malkangiri",
            "hi": "मलकानगिरी"
        },
        {
            "en": "Mayurbhanj",
            "hi": "मयूरभंज"
        },
        {
            "en": "Nabarangpur",
            "hi": "नबरंगपुर"
        },
        {
            "en": "Nayagarh",
            "hi": "नयागढ़"
        },
        {
            "en": "Nuapada",
            "hi": "नुआपाड़ा"
        },
        {
            "en": "Puri",
            "hi": "पुरी"
        },
        {
            "en": "Rayagada",
            "hi": "रायगड़ा"
        },
        {
            "en": "Sambalpur",
            "hi": "संबलपुर"
        },
        {
            "en": "Sonepur",
            "hi": "सोनेपुर"
        },
        {
            "en": "Sundargarh",
            "hi": "सुंदरगढ़"
        }
    ],
    "Puducherry": [
        {
            "en": "Karaikal",
            "hi": "कराईकल"
        },
        {
            "en": "Puducherry",
            "hi": "पुडुचेरी"
        }
    ],
    "Punjab": [
        {
            "en": "Amritsar",
            "hi": "अमृतसर"
        },
        {
            "en": "Barnala",
            "hi": "बरनाला"
        },
        {
            "en": "Bathinda",
            "hi": "बठिंडा"
        },
        {
            "en": "Faridkot",
            "hi": "फरीदकोट"
        },
        {
            "en": "Fatehgarh Sahib",
            "hi": "फतेहगढ़ साहिब"
        },
        {
            "en": "Fazilka",
            "hi": "फाजिल्का"
        },
        {
            "en": "Ferozepur",
            "hi": "फिरोजपुर"
        },
        {
            "en": "Gurdaspur",
            "hi": "गुरदासपुर"
        },
        {
            "en": "Hoshiarpur",
            "hi": "होशियारपुर"
        },
        {
            "en": "Jalandhar",
            "hi": "जालंधर"
        },
        {
            "en": "Kapurthala",
            "hi": "कपूरथला"
        },
        {
            "en": "Ludhiana",
            "hi": "लुधियाना"
        },
        {
            "en": "Malerkotla",
            "hi": "मलेरकोटला"
        },
        {
            "en": "Mansa",
            "hi": "मनसा"
        },
        {
            "en": "Moga",
            "hi": "मोगा"
        },
        {
            "en": "Pathankot",
            "hi": "पठानकोट"
        },
        {
            "en": "Patiala",
            "hi": "पटियाला"
        },
        {
            "en": "Rupnagar",
            "hi": "रूपनगर"
        },
        {
            "en": "Sangrur",
            "hi": "संगरूर"
        },
        {
            "en": "S.A.S Nagar",
            "hi": "एस.ए.एस नगर"
        },
        {
            "en": "Shahid Bhagat Singh Nagar",
            "hi": "शहीद भगत सिंह नगर"
        },
        {
            "en": "Sri Muktsar Sahib",
            "hi": "श्री मुक्तसर साहिब"
        },
        {
            "en": "Tarn Taran",
            "hi": "तरनतारन"
        }
    ],
    "Rajasthan": [
        {
            "en": "Ajmer",
            "hi": "अजमेर"
        },
        {
            "en": "Alwar",
            "hi": "अलवर"
        },
        {
            "en": "Anupgarh",
            "hi": "अनूपगढ़"
        },
        {
            "en": "Balotra",
            "hi": "बालोतरा"
        },
        {
            "en": "Banswara",
            "hi": "बांसवाड़ा"
        },
        {
            "en": "Baran",
            "hi": "बारां"
        },
        {
            "en": "Barmer",
            "hi": "बाड़मेर"
        },
        {
            "en": "Beawar",
            "hi": "ब्यावर"
        },
        {
            "en": "Bharatpur",
            "hi": "भरतपुर"
        },
        {
            "en": "Bhilwara",
            "hi": "भीलवाड़ा"
        },
        {
            "en": "Bikaner",
            "hi": "बीकानेर"
        },
        {
            "en": "Bundi",
            "hi": "बूंदी"
        },
        {
            "en": "Chittorgarh",
            "hi": "चित्तौड़गढ़"
        },
        {
            "en": "Churu",
            "hi": "चूरू"
        },
        {
            "en": "Dausa",
            "hi": "दौसा"
        },
        {
            "en": "Deeg",
            "hi": "डीग"
        },
        {
            "en": "Dholpur",
            "hi": "धौलपुर"
        },
        {
            "en": "Didwana-Kuchaman",
            "hi": "डीडवाना-कुचामन"
        },
        {
            "en": "Dudu",
            "hi": "दूदू"
        },
        {
            "en": "Dungarpur",
            "hi": "डूंगरपुर"
        },
        {
            "en": "Ganganagar",
            "hi": "गंगानगर"
        },
        {
            "en": "Gangapurcity",
            "hi": "गंगापुर सिटी"
        },
        {
            "en": "Hanumangarh",
            "hi": "हनुमानगढ़"
        },
        {
            "en": "Jaipur",
            "hi": "जयपुर"
        },
        {
            "en": "Jaipur (Gramin)",
            "hi": "जयपुर (ग्रामीण)"
        },
        {
            "en": "Jaisalmer",
            "hi": "जैसलमेर"
        },
        {
            "en": "Jalore",
            "hi": "जालोर"
        },
        {
            "en": "Jhalawar",
            "hi": "झालावाड़"
        },
        {
            "en": "Jhunjhunu",
            "hi": "झुंझुनू"
        },
        {
            "en": "Jodhpur",
            "hi": "जोधपुर"
        },
        {
            "en": "Jodhpur (Gramin)",
            "hi": "जोधपुर (ग्रामीण)"
        },
        {
            "en": "Karauli",
            "hi": "करौली"
        },
        {
            "en": "Kekri",
            "hi": "केकरी"
        },
        {
            "en": "Khairthal-Tijara",
            "hi": "खैरथल-तिजारा"
        },
        {
            "en": "Kota",
            "hi": "कोटा"
        },
        {
            "en": "Kotputli-Behror",
            "hi": "कोटपूतली-बहरोड़"
        },
        {
            "en": "Nagaur",
            "hi": "नागौर"
        },
        {
            "en": "Neem Ka Thana",
            "hi": "नीम का थाना"
        },
        {
            "en": "Pali",
            "hi": "पाली"
        },
        {
            "en": "Phalodi",
            "hi": "फलोदी"
        },
        {
            "en": "Pratapgarh",
            "hi": "प्रतापगढ़"
        },
        {
            "en": "Rajsamand",
            "hi": "राजसमंद"
        },
        {
            "en": "Salumbar",
            "hi": "सलूंबर"
        },
        {
            "en": "Sanchore",
            "hi": "सान्चोर"
        },
        {
            "en": "Sawai Madhopur",
            "hi": "सवाई माधोपुर"
        },
        {
            "en": "Shahpura",
            "hi": "शाहपुरा"
        },
        {
            "en": "Sikar",
            "hi": "सीकर"
        },
        {
            "en": "Sirohi",
            "hi": "सिरोही"
        },
        {
            "en": "Tonk",
            "hi": "टोंक"
        },
        {
            "en": "Udaipur",
            "hi": "उदयपुर"
        }
    ],
    "Sikkim": [
        {
            "en": "Gangtok",
            "hi": "गंगटोक"
        },
        {
            "en": "Gyalshing",
            "hi": "ग्यालशिंग"
        },
        {
            "en": "Mangan",
            "hi": "मंगन"
        },
        {
            "en": "Namchi",
            "hi": "नामची"
        },
        {
            "en": "Pakyong",
            "hi": "पाक्योंग"
        },
        {
            "en": "Soreng",
            "hi": "सोरेंग"
        }
    ],
    "Tamil Nadu": [
        {
            "en": "Ariyalur",
            "hi": "अरियालूर"
        },
        {
            "en": "Chengalpattu",
            "hi": "चेंगलपट्टू"
        },
        {
            "en": "Chennai",
            "hi": "चेन्नई"
        },
        {
            "en": "Coimbatore",
            "hi": "कोयंबटूर"
        },
        {
            "en": "Cuddalore",
            "hi": "कुड्डालोर"
        },
        {
            "en": "Dharmapuri",
            "hi": "धर्मपुरी"
        },
        {
            "en": "Dindigul",
            "hi": "डिंडीगुल"
        },
        {
            "en": "Erode",
            "hi": "इरोड"
        },
        {
            "en": "Kallakurichi",
            "hi": "कल्लाकुरिची"
        },
        {
            "en": "Kancheepuram",
            "hi": "कांचीपुरम"
        },
        {
            "en": "Kanniyakumari",
            "hi": "कन्याकुमारी"
        },
        {
            "en": "Karur",
            "hi": "करूर"
        },
        {
            "en": "Krishnagiri",
            "hi": "कृष्णागिरि"
        },
        {
            "en": "Madurai",
            "hi": "मदुरै"
        },
        {
            "en": "Mayiladuthurai",
            "hi": "मयिलादुथुराई"
        },
        {
            "en": "Nagapattinam",
            "hi": "नागपट्टिनम"
        },
        {
            "en": "Namakkal",
            "hi": "नमक्कल"
        },
        {
            "en": "Perambalur",
            "hi": "पेरम्बलूर"
        },
        {
            "en": "Pudukkottai",
            "hi": "पुदुक्कोट्टई"
        },
        {
            "en": "Ramanathapuram",
            "hi": "रामनाथपुरम"
        },
        {
            "en": "Ranipet",
            "hi": "रानीपेट"
        },
        {
            "en": "Salem",
            "hi": "सेलम"
        },
        {
            "en": "Sivaganga",
            "hi": "शिवगंगा"
        },
        {
            "en": "Tenkasi",
            "hi": "तेनकासी"
        },
        {
            "en": "Thanjavur",
            "hi": "तंजावुर"
        },
        {
            "en": "Theni",
            "hi": "तेनी"
        },
        {
            "en": "The Nilgiris",
            "hi": "नीलगिरी"
        },
        {
            "en": "Thiruvallur",
            "hi": "तिरुवल्लुर"
        },
        {
            "en": "Thiruvarur",
            "hi": "तिरुवारूर"
        },
        {
            "en": "Thoothukkudi",
            "hi": "तूतीकोरिन"
        },
        {
            "en": "Tiruchirappalli",
            "hi": "तिरुचिरापल्ली"
        },
        {
            "en": "Tirunelveli",
            "hi": "तिरुनेलवेली"
        },
        {
            "en": "Tirupathur",
            "hi": "तिरुप्पत्तूर"
        },
        {
            "en": "Tiruppur",
            "hi": "तिरुप्पुर"
        },
        {
            "en": "Tiruvannamalai",
            "hi": "तिरुवन्नामलाई"
        },
        {
            "en": "Vellore",
            "hi": "वेल्लोर"
        },
        {
            "en": "Viluppuram",
            "hi": "विलुप्पुरम"
        },
        {
            "en": "Virudhunagar",
            "hi": "विरुधुनगर"
        }
    ],
    "Telangana": [
        {
            "en": "Adilabad",
            "hi": "आदिलाबाद"
        },
        {
            "en": "Bhadradri Kothagudem",
            "hi": "भद्राद्री कोठागुडेम"
        },
        {
            "en": "Hanumakonda",
            "hi": "हनुमाकोंडा"
        },
        {
            "en": "Hyderabad",
            "hi": "हैदराबाद"
        },
        {
            "en": "Jagitial",
            "hi": "जगितियाल"
        },
        {
            "en": "Jangoan",
            "hi": "जनगांव"
        },
        {
            "en": "Jayashankar Bhupalapally",
            "hi": "जयशंकर भूपालपल्ली"
        },
        {
            "en": "Jogulamba Gadwal",
            "hi": "जोगुलांबा गडवाल"
        },
        {
            "en": "Kamareddy",
            "hi": "कामारेड्डी"
        },
        {
            "en": "Karimnagar",
            "hi": "करीमनगर"
        },
        {
            "en": "Khammam",
            "hi": "खम्मम"
        },
        {
            "en": "Kumuram Bheem Asifabad",
            "hi": "कुमराम भीम आसिफाबाद"
        },
        {
            "en": "Mahabubabad",
            "hi": "महबूबाबाद"
        },
        {
            "en": "Mahabubnagar",
            "hi": "महबूबनगर"
        },
        {
            "en": "Mancherial",
            "hi": "मंचेरियल"
        },
        {
            "en": "Medak",
            "hi": "मेदक"
        },
        {
            "en": "Medchal Malkajgiri",
            "hi": "मेडचल मलकाजगिरी"
        },
        {
            "en": "Mulugu",
            "hi": "मुलुगु"
        },
        {
            "en": "Nagarkurnool",
            "hi": "नागरकुरनूल"
        },
        {
            "en": "Nalgonda",
            "hi": "नलगोंडा"
        },
        {
            "en": "Narayanpet",
            "hi": "नारायणपेट"
        },
        {
            "en": "Nirmal",
            "hi": "निर्मल"
        },
        {
            "en": "Nizamabad",
            "hi": "निजामाबाद"
        },
        {
            "en": "Peddapalli",
            "hi": "पेद्दापल्ली"
        },
        {
            "en": "Rajanna Sircilla",
            "hi": "राजन्ना सिरसिल्ला"
        },
        {
            "en": "Ranga Reddy",
            "hi": "रंगारेड्डी"
        },
        {
            "en": "Sangareddy",
            "hi": "संगारेड्डी"
        },
        {
            "en": "Siddipet",
            "hi": "सिद्दिपेट"
        },
        {
            "en": "Suryapet",
            "hi": "सूर्यापेट"
        },
        {
            "en": "Vikarabad",
            "hi": "विकाराबाद"
        },
        {
            "en": "Wanaparthy",
            "hi": "वानापार्थी"
        },
        {
            "en": "Warangal",
            "hi": "वारंगल"
        },
        {
            "en": "Yadadri Bhuvanagiri",
            "hi": "यादाद्री भुवनगिरी"
        }
    ],
    "The Dadra And Nagar Haveli And Daman And Diu": [
        {
            "en": "Dadra And Nagar Haveli",
            "hi": "दादरा और नगर हवेली"
        },
        {
            "en": "Daman",
            "hi": "दमन"
        },
        {
            "en": "Diu",
            "hi": "दिउ"
        }
    ],
    "Tripura": [
        {
            "en": "Dhalai",
            "hi": "धलाई"
        },
        {
            "en": "Gomati",
            "hi": "गोमती"
        },
        {
            "en": "Khowai",
            "hi": "खोवाई"
        },
        {
            "en": "North Tripura",
            "hi": "उत्तरी त्रिपुरा"
        },
        {
            "en": "Sepahijala",
            "hi": "सिपाहीजला"
        },
        {
            "en": "South Tripura",
            "hi": "दक्षिण त्रिपुरा"
        },
        {
            "en": "Unakoti",
            "hi": "उनाकोटी"
        },
        {
            "en": "West Tripura",
            "hi": "पश्चिम त्रिपुरा"
        }
    ],
    "Uttarakhand": [
        {
            "en": "Almora",
            "hi": "अल्मोड़ा"
        },
        {
            "en": "Bageshwar",
            "hi": "बागेश्वर"
        },
        {
            "en": "Chamoli",
            "hi": "चमोली"
        },
        {
            "en": "Champawat",
            "hi": "चंपावत"
        },
        {
            "en": "Dehradun",
            "hi": "देहरादून"
        },
        {
            "en": "Haridwar",
            "hi": "हरिद्वार"
        },
        {
            "en": "Nainital",
            "hi": "नैनीताल"
        },
        {
            "en": "Pauri Garhwal",
            "hi": "पौड़ी गढ़वाल"
        },
        {
            "en": "Pithoragarh",
            "hi": "पिथौरागढ़"
        },
        {
            "en": "Rudra Prayag",
            "hi": "रुद्रप्रयाग"
        },
        {
            "en": "Tehri Garhwal",
            "hi": "टिहरी गढ़वाल"
        },
        {
            "en": "Udam Singh Nagar",
            "hi": "उधम सिंह नगर"
        },
        {
            "en": "Uttar Kashi",
            "hi": "उत्तरकाशी"
        }
    ],
    "Uttar Pradesh": [
        {
            "en": "Agra",
            "hi": "आगरा"
        },
        {
            "en": "Aligarh",
            "hi": "अलीगढ़"
        },
        {
            "en": "Ambedkar Nagar",
            "hi": "अंबेडकर नगर"
        },
        {
            "en": "Amethi",
            "hi": "अमेठी"
        },
        {
            "en": "Amroha",
            "hi": "अमरोहा"
        },
        {
            "en": "Auraiya",
            "hi": "औरैया"
        },
        {
            "en": "Ayodhya",
            "hi": "अयोध्या"
        },
        {
            "en": "Azamgarh",
            "hi": "आजमगढ़"
        },
        {
            "en": "Baghpat",
            "hi": "बागपत"
        },
        {
            "en": "Bahraich",
            "hi": "बहराइच"
        },
        {
            "en": "Ballia",
            "hi": "बलिया"
        },
        {
            "en": "Balrampur",
            "hi": "बलरामपुर"
        },
        {
            "en": "Banda",
            "hi": "बांदा"
        },
        {
            "en": "Bara Banki",
            "hi": "बाराबंकी"
        },
        {
            "en": "Bareilly",
            "hi": "बरेली"
        },
        {
            "en": "Basti",
            "hi": "बस्ती"
        },
        {
            "en": "Bhadohi",
            "hi": "भदोही"
        },
        {
            "en": "Bijnor",
            "hi": "बिजनौर"
        },
        {
            "en": "Budaun",
            "hi": "बदायूं"
        },
        {
            "en": "Bulandshahr",
            "hi": "बुलंदशहर"
        },
        {
            "en": "Chandauli",
            "hi": "चंदौली"
        },
        {
            "en": "Chitrakoot",
            "hi": "चित्रकूट"
        },
        {
            "en": "Deoria",
            "hi": "देवरिया"
        },
        {
            "en": "Etah",
            "hi": "एटा"
        },
        {
            "en": "Etawah",
            "hi": "इटावा"
        },
        {
            "en": "Farrukhabad",
            "hi": "फर्रुखाबाद"
        },
        {
            "en": "Fatehpur",
            "hi": "फतेहपुर"
        },
        {
            "en": "Firozabad",
            "hi": "फिरोजाबाद"
        },
        {
            "en": "Gautam Buddha Nagar",
            "hi": "गौतम बुद्ध नगर"
        },
        {
            "en": "Ghaziabad",
            "hi": "गाज़ियाबाद"
        },
        {
            "en": "Ghazipur",
            "hi": "गाजीपुर"
        },
        {
            "en": "Gonda",
            "hi": "गोंडा"
        },
        {
            "en": "Gorakhpur",
            "hi": "गोरखपुर"
        },
        {
            "en": "Hamirpur",
            "hi": "हमीरपुर"
        },
        {
            "en": "Hapur",
            "hi": "हापुड़"
        },
        {
            "en": "Hardoi",
            "hi": "हरदोई"
        },
        {
            "en": "Hathras",
            "hi": "हाथरस"
        },
        {
            "en": "Jalaun",
            "hi": "जालौन"
        },
        {
            "en": "Jaunpur",
            "hi": "जौनपुर"
        },
        {
            "en": "Jhansi",
            "hi": "झांसी"
        },
        {
            "en": "Kannauj",
            "hi": "कन्नौज"
        },
        {
            "en": "Kanpur Dehat",
            "hi": "कानपुर देहात"
        },
        {
            "en": "Kanpur Nagar",
            "hi": "कानपुर नगर"
        },
        {
            "en": "Kasganj",
            "hi": "कासगंज"
        },
        {
            "en": "Kaushambi",
            "hi": "कौशांबी"
        },
        {
            "en": "Kheri",
            "hi": "खीरी"
        },
        {
            "en": "Kushinagar",
            "hi": "कुशीनगर"
        },
        {
            "en": "Lalitpur",
            "hi": "ललितपुर"
        },
        {
            "en": "Lucknow",
            "hi": "लखनऊ"
        },
        {
            "en": "Mahoba",
            "hi": "महोबा"
        },
        {
            "en": "Mahrajganj",
            "hi": "महराजगंज"
        },
        {
            "en": "Mainpuri",
            "hi": "मैनपुरी"
        },
        {
            "en": "Mathura",
            "hi": "मथुरा"
        },
        {
            "en": "Mau",
            "hi": "मऊ"
        },
        {
            "en": "Meerut",
            "hi": "मेरठ"
        },
        {
            "en": "Mirzapur",
            "hi": "मिर्जापुर"
        },
        {
            "en": "Moradabad",
            "hi": "मुरादाबाद"
        },
        {
            "en": "Muzaffarnagar",
            "hi": "मुज़फ्फरनगर"
        },
        {
            "en": "Pilibhit",
            "hi": "पीलीभीत"
        },
        {
            "en": "Pratapgarh",
            "hi": "प्रतापगढ़"
        },
        {
            "en": "Prayagraj",
            "hi": "प्रयागराज"
        },
        {
            "en": "Rae Bareli",
            "hi": "रायबरेली"
        },
        {
            "en": "Rampur",
            "hi": "रामपुर"
        },
        {
            "en": "Saharanpur",
            "hi": "सहारनपुर"
        },
        {
            "en": "Sambhal",
            "hi": "संभल"
        },
        {
            "en": "Sant Kabir Nagar",
            "hi": "संत कबीर नगर"
        },
        {
            "en": "Shahjahanpur",
            "hi": "शाहजहांपुर"
        },
        {
            "en": "Shamli",
            "hi": "शामली"
        },
        {
            "en": "Shrawasti",
            "hi": "श्रावस्ती"
        },
        {
            "en": "Siddharthnagar",
            "hi": "सिद्धार्थनगर"
        },
        {
            "en": "Sitapur",
            "hi": "सीतापुर"
        },
        {
            "en": "Sonbhadra",
            "hi": "सोनभद्र"
        },
        {
            "en": "Sultanpur",
            "hi": "सुल्तानपुर"
        },
        {
            "en": "Unnao",
            "hi": "उन्नाव"
        },
        {
            "en": "Varanasi",
            "hi": "वाराणसी"
        }
    ],
    "West Bengal": [
        {
            "en": "Alipurduar",
            "hi": "अलीपुरद्वार"
        },
        {
            "en": "Bankura",
            "hi": "बांकुड़ा"
        },
        {
            "en": "Birbhum",
            "hi": "बीर्बम"
        },
        {
            "en": "Cooch Behar",
            "hi": "कूचबिहार"
        },
        {
            "en": "Dakshin Dinajpur",
            "hi": "दक्षिण दिनाजपुर"
        },
        {
            "en": "Darjeeling",
            "hi": "दार्जिलिंग"
        },
        {
            "en": "Hooghly",
            "hi": "हुगली"
        },
        {
            "en": "Howrah",
            "hi": "हावड़ा"
        },
        {
            "en": "Jalpaiguri",
            "hi": "जलपाईगुड़ी"
        },
        {
            "en": "Jhargram",
            "hi": "झारग्राम"
        },
        {
            "en": "Kalimpong",
            "hi": "कालिम्पोंग"
        },
        {
            "en": "Kolkata",
            "hi": "कोलकाता"
        },
        {
            "en": "Malda",
            "hi": "मालदा"
        },
        {
            "en": "Murshidabad",
            "hi": "मुर्शिदाबाद"
        },
        {
            "en": "Nadia",
            "hi": "नदिया"
        },
        {
            "en": "North 24 Parganas",
            "hi": "उत्तर 24 परगना"
        },
        {
            "en": "Paschim Bardhaman",
            "hi": "पश्चिम बर्दवान"
        },
        {
            "en": "Paschim Medinipur",
            "hi": "पश्चिम मेदिनीपुर"
        },
        {
            "en": "Purba Bardhaman",
            "hi": "पूर्व बर्दवान"
        },
        {
            "en": "Purba Medinipur",
            "hi": "पूर्व मेदिनीपुर"
        },
        {
            "en": "Purulia",
            "hi": "पुरुलिया"
        },
        {
            "en": "South 24 Parganas",
            "hi": "दक्षिण 24 परगना"
        },
        {
            "en": "Uttar Dinajpur",
            "hi": "उत्तर दिनाजपुर"
        }
    ]
}
