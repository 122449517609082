// export const CheckMobileNumber = "CheckMobileNumber";
// export const SendOtp = "SendOtp";
// export const VerifyOtp = "VerifyOtp";
// export const SaveRegistration = "SaveRegistration";
// export const GenerateAudio = "GenerateAudio";
// export const Transcribe = "Transcribe";
// export const Submit = "Submit";
// export const Fetch = "Fetch";
// export const Starred = "Starred";
// export const ChatFeedback = "ChatFeedback";


// export function checkAPISuccess (action, status_code, message="") {
//     if (status_code === 200) {
//         return true;
//     }
//     if (status_code === 401) {
//         if (action === VerifyOtp) {
//             return {
//                 "message": loginErrorLabels[otp]
//             }
//         }
//         else {
//             return null;
//         }
        
//     }
    
//     if (action === CheckMobileNumber) {
        
//         else {
//             return {

//             }
//         }
//     }
// }