import qs from "query-string";

export const fetchApi = async (url, method = "GET", body = {}, headers = new Headers(), params = {}) => {
  // Initialize request object
  const obj = { method, headers };

// Configure the URL based on the HTTP method
  if (method === 'DELETE') {
    url = qs.stringifyUrl({ url, query: body });
  } else if (method === 'GET') {
    url = qs.stringifyUrl({ url, query: params });
  } else {
    obj.body = body; // Add body for non-GET/DELETE requests
  }

  try {
    // Fetch the API response
    const response = await fetch(url, obj);
    const contentType = response.headers.get("Content-Type") || '';

    if (!response.ok) {
      // Return error text if response is not okay
      return {"status": response.status, "data": await response.json()};
    }

    if (contentType.includes("application/json")) {
      return {"status": response.status, "data": await response.json()};
    } else {
      return response;
    }
  } catch (e) {
    // Handle any fetch errors
    return { error: e.message };
  }
};
