import { userType } from "../Constants/UserContants";
import {
  getUserDataApi,
  registerApi,
  updateProfileApi,
  verifyMobileNumberApi,
  resendOtpApi,
  verifyOtpApi
} from "../ENV";
import { fetchApi } from "./FetchAPI";


export async function verifyMobileNumber(mobileNumber) {
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json"
  };

  const res = await fetchApi(verifyMobileNumberApi, "GET", {}, headerValue, {"mobile_number": mobileNumber});
  
  return res;
}

export async function resendOtp(mobileNumber) {
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json"
  };

  const res = await fetchApi(resendOtpApi, "POST", JSON.stringify({"mobile": mobileNumber}), headerValue, {});
  return res;
}


export async function verifyOtp(mobileNumber, otp) {
  var payloadJSON = {
    mobile: mobileNumber,
    otp: otp
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
  };
  const res = await fetchApi(
    verifyOtpApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res;
}

export async function register(mobileNumber, formData) {
  let formDataObj = {...formData}
  formDataObj.mobile = mobileNumber
  var payloadJSON = {
    formData: formDataObj
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
  };

  const res = await fetchApi(
    registerApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res;
}

export async function resetPassword(access_token, oldPassword, newPassword) {
  var payloadJSON = {
    old_password: oldPassword,
    new_password: newPassword,
  };
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: "Bearer " + access_token,
  };

  const res = await fetchApi(
    updateProfileApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res;
}

export async function updateProfile(access_token, updateObj) {
  var payloadJSON = updateObj;
  var headerValue = {
    Accept: "application/json",
    "Content-type": "application/json",
    Authorization: "Bearer " + access_token,
  };

  const res = await fetchApi(
    updateProfileApi,
    "POST",
    JSON.stringify(payloadJSON),
    headerValue,
    {}
  );
  return res;
}

export async function getUserData(access_token) {
  var headerValue = {
    "x-access-token": access_token,
  };
  const res = await fetchApi(getUserDataApi, "GET", {}, headerValue, {});
  return res.Response;
}
