import { useState, useEffect } from "react";
import NextBtn from "../../../../Shared/Components/NextBtn/NextBtn";
import { useNavigate } from "react-router-dom";

const RegsiterSuccessComponent = ({project, selectedLanguage}) => {
    const [regsiterSuccessConstants, setRegsiterSuccessConstants] = useState(null);
    const navigate = useNavigate();

    const loginFun = async () => {
        navigate('/otp-verify');
    }

    useEffect(() => {
        const loadConstants = async () => {
          const registerSuccessModule = await import(`../../../../Constants/${project}/RegisterConstants`);
          setRegsiterSuccessConstants(registerSuccessModule);
        };
    
        loadConstants();
    
        window.history.pushState(null, document.title, window.location.href);
    
        const handlePopState = (event) => {
          window.history.pushState(null, document.title, window.location.href);
        };
    
        window.addEventListener('popstate', handlePopState);
        return () => {
          window.removeEventListener('popstate', handlePopState);
        };
      }, []);

    if (!regsiterSuccessConstants) {
        return <div>Loading...</div>;
    }

    const { regsiterSuccess, loginButtonLabel } = regsiterSuccessConstants;

    return (
        <div className="bg-[#3237A0] h-screen w-screen relative bg-blend-color-burn flex flex-col items-center justify-center" style={{ backgroundImage: `url("/Assets/Images/wallpaperBg.jpg")` }}>
            <img src="/Assets/Images/successTick.svg" alt="success" />
            <div className="text-white font-bold text-[2rem] w-[40%] py-8 text-center">
                {regsiterSuccess.title[selectedLanguage]}
            </div>
            <div className="text-white text-center w-[60%] pb-10">
                {regsiterSuccess.description[selectedLanguage]}
            </div>
            <div>
            <NextBtn
                value={loginButtonLabel[selectedLanguage]}
                icon={"/Assets/Images/greenArrowIcon.svg"}
                bgColor={"var(--neutral-white)"}
                color={"var(--green-1)"}
                nextClick={loginFun}
                padx={48}
                bold={true}
                centerSpacing={true}
            />
            </div>
        </div>
    )
}

export default RegsiterSuccessComponent;